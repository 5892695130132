import axios from 'axios';
import { addDays, format } from 'date-fns';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { Message } from 'primereact/message';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Component from '../../../../constants/Component';
import img from '../../../../constants/Img';
import { SharedData } from '../../../../context/CallApi';
import { FiltersApis } from '../../../../context/CarFiltersApis';
import { CarFilterStates } from '../../../../context/FiltersCars';
import { LocalizationContext } from '../../../../context/LangChange';
import useValidation from '../../../auth/Services/useValidation';
import './SeacrchCar.scss';

const SearchCar = ({ branchesHome, carBrandsHome }) => {
  let { isLang } = useContext(LocalizationContext);
  const [modalDone, setModalDone] = useState(false);
  const location = useLocation();
  let navigate = useNavigate();

  let { setPage, setCarData, LimozinPlacesData, setCarBrandId, CarTypes, setRows, setFirst } = useContext(FiltersApis);
  let { setStoreBrancheTo,
    setStoreBrancheForm,
    setStoreBranches,
    setStoreBrand,
    setStoreStartDate,
    setClacDateLim,
    storeTime,
    setStoreTime,
    setTypeIdCar,
    setStoreEndDate } = useContext(CarFilterStates);

  const toast = useRef(null);
  let { fetchPersonalData, setValue, fetchConfig } = useContext(SharedData);
  let { activeBtn, setActiveBtn } = useContext(CarFilterStates);

  const [activeSubBtn, setActiveSubBtn] = useState('btn4');
  const [selectedTaps, setSelectedTaps] = useState('افراد ');

  const handleBtnClick = (btn) => {
    setActiveBtn(btn);
  };
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const findCarTypeParam = urlSearchParams.get('find-car-type');
    if (findCarTypeParam === 'long-term-rent') {
      setActiveBtn('btn2')
    } else if (findCarTypeParam === 'Companies') {
      setActiveBtn('btn1')
    } else if (findCarTypeParam === 'limousine') {
      setActiveBtn('btn3')
    }

    if (location.pathname === "/") {
      setActiveSubBtn('btn4')
    } else if (location.pathname === "/cars/limousine") {
      setActiveSubBtn('btn5')
    }
  }, [])

  const LimozinPlacesDataForm = [{
    name: isLang === "en" ? "  Another " : 'اخري', id: 0
  }
    , ...LimozinPlacesData.map((city) => ({
      name: isLang === "en" ? city.name : city.arabic_name,
      id: city.id,
    }))];
  const LimozinPlacesDataTo = [{
    name: isLang === "en" ? "  Another" : 'اخري', id: 0
  }
    , ...LimozinPlacesData.map((city) => ({
      name: isLang === "en" ? city.name : city.arabic_name,
      id: city.id,
    }))];
  const branche = [{
    name: isLang === "en" ? "Select all" : 'الجميع', id: 0
  }
    , ...branchesHome.map((city) => ({
      name: isLang === "en" ? city.name : city.arabic_name,
      id: city.id,
    }))];
  const brancheFrom = [{
    name: isLang === "en" ? "Select all" : 'الجميع', id: 0
  }, ...branchesHome.map((city) => ({
    name: isLang === "en" ? city.name : city.arabic_name,
    id: city.id,
  }))];
  const brancheTo = [{
    name: isLang === "en" ? "Select all" : 'الجميع', id: 0
  }, ...branchesHome.map((city) => ({
    name: isLang === "en" ? city.name : city.arabic_name,
    id: city.id,
  }))];
  const brands = [{
    name: isLang === "en" ? "Select all" : 'الجميع', id: 0
  }, ...carBrandsHome.map((city) => ({
    name: isLang === "en" ? city.name : city.arabic_name,
    id: city.id,
  }))];

  const brandsMultiSelect = [...carBrandsHome.map((city) => ({
    name: isLang === "en" ? city.name : city.arabic_name,
    id: city.id,
  })), {
    name: isLang === "en" ? "Other cars" : 'سيارات أخرى', id: 0
  }];
  const carTypes = [{
    name: isLang === "en" ? "Select all" : 'الجميع', id: 0
  }, ...CarTypes.map((city) => ({
    name: isLang === "en" ? city.name : city.arabic_name,
    id: city.id,
  }))];
  const taps = [
    { name: isLang === "en" ? 'Individuals' : 'افراد ', btn: 'btn1' },
    { name: isLang === "en" ? 'Companies' : 'الشركــات', btn: 'btn2' },
    // { name: isLang === "en" ? '  Limousine   ' : '    ليموزين  ', btn: 'btn3' },
  ];

  const handleTapsSelect = (taps) => {
    setSelectedTaps(taps);
    setActiveBtn(taps.btn === "btn1" ? 'btn1' : taps.btn === "btn2" ? 'btn2' : taps.btn === "btn3" && 'btn3');
    if (taps.btn === "btn1") {
      navigator(`/${isLang}/?find-car-type=short-term-rent`)
    } else if (taps.btn === "btn2") {
      navigator(`/${isLang}/?find-car-type=long-term-rent`)
    } else if (taps.btn === "btn3") {
      navigator(`/${isLang}/?find-car-type=long-term-rent`)

    }
  };

  let { validationDate, validateLongTermRent, validateLimozinRequest, validateLimozinRequest8H } = useValidation()


  const formik = useFormik({
    initialValues: {
      branchId: '',
      branchIdFrom: '',
      branchIdTo: '',
      brandId: '',
      typeId: '',
      start: '',
      end: ''
    },
    validationSchema: validationDate,

    onSubmit: async (values) => {
      setCarData([]);
      if (values.start instanceof Date) {
        values.start = format(values.start, 'dd/MM/yyyy');
      }
      if (values.end instanceof Date) {
        values.end = format(values.end, 'dd/MM/yyyy');
      }

      const branchIdParam = values.branchId ? `branchId=${values.branchId}` : '';
      const branchIdFromParam = values.branchIdFrom ? `&branchId=${values.branchIdFrom}` : '';
      const brandIdParam = values.brandId ? `&brandId=${values.brandId}` : '';
      const typeIdParam = values.typeId ? `&typeId=${values.typeId}` : '';
      const startParam = values.start ? `&start=${values.start}` : '';
      const endParam = values.end ? `&end=${values.end}` : '';
      const url = `${process.env.REACT_APP_API_URL}/cars?${branchIdParam}${brandIdParam}${startParam}${endParam}${branchIdFromParam}${typeIdParam}`;
      let { data } = await axios.get(url)
      setValue([])
      fetchConfig()
      setPage('');
      setCarData(data);
      setFirst(0)
      setRows(1)
      setPage(Object.values(data).length);

      if (!values.branchId) {
        setStoreBranches(null)
      }
      if (!values.branchIdFrom) {
        setStoreBrancheForm('')
      }
      if (!values.branchIdTo) {
        setStoreBrancheTo(null)
      }
      if (!values.brandId) {
        setStoreBrand(null)
      }

      if (!values.typeId) {
        setTypeIdCar(null)
      }

      const selectedBrand = brands.find((brand) => brand.id === values.brandId);
      const selectedType = carTypes.find((type) => type.id === values.typeId);

      const brandUrl = values.brandId ? `?car-brand=${selectedBrand?.name}` : '';
      const subIdUrl = values.typeId ? `?car-type=${selectedType?.name}` : '';
      const startUrl = values.start ? `?start-date=${values.start}` : '';
      const endUrl = values.end ? `?end-date=${values.end}` : '';
      navigate(`/${brandUrl}${subIdUrl}${startUrl}${endUrl}`)
      navigate(`/cars${brandUrl}${subIdUrl}${startUrl}${endUrl}`)
    }
  });
  const [selectedBranchName, setSelectedBranchName] = useState('');

  useEffect(() => {
    // تحديث اسم الفرع المحدد عندما يتم تغيير قيمة formik.values.branchId
    const selectedBranch = branche.find((branch) => branch.value === formik.values.branchId);
    if (selectedBranch) {
      setSelectedBranchName(selectedBranch.label);
    } else {
      setSelectedBranchName('');
    }


  }, [formik.values.branchId, branche]);
  const [selectedCities, setSelectedCities] = useState(null);

  const formikLongTermRent = useFormik({
    initialValues: {
      number_cars: '',
      months: '',
      car_brand: '',
      company_name: '',
      city: '',
      // address: '',
      activity: '',
      // reg_number: '',
      person: fetchPersonalData?.first_name !== null ? fetchPersonalData.first_name : '',
      mobile: fetchPersonalData?.mobile !== null ? fetchPersonalData.mobile : '',
      email: fetchPersonalData?.email !== null ? fetchPersonalData.email : '',
    },
    validationSchema: validateLongTermRent,
    onSubmit: async (values) => {

      var carBrandName = selectedCities.map(function (obj) {
        return obj.name;
      });

      // const url = `${process.env.REACT_APP_API_URL}/longtermrent?number_cars=${values.number_cars}&months=${values.months}&car_brand=${values.car_brand}&company_name=${values.company_name}&city=${values.city}&address=${values.address}&activity=${values.activity}&reg_number=${values.reg_number}&person=${values.person}&mobile=${values.mobile}&email=${values.email}`;
      const url = `${process.env.REACT_APP_API_URL}/longtermrent?number_cars=${values.number_cars}&months=${values.months}&car_brand=${carBrandName.join(', ')}&company_name=${values.company_name}&city=${values.city}&activity=${values.activity}&person=${values.person}&mobile=${values.mobile}&email=${values.email}`;
      let { data } = await axios.post(url)
      if (data.status) {
        setModalDone(true)
        setTimeout(() => {
          setModalDone(false)
        }, 1500);
        resetFormikLongTermRent()
      }
    }
  });
  const resetFormikLongTermRent = () => {
    setSelectedCities(null)
    formikLongTermRent.values.number_cars = '';
    formikLongTermRent.values.months = '';
    formikLongTermRent.values.car_brand = '';
    formikLongTermRent.values.company_name = '';
    formikLongTermRent.values.city = '';
    // formikLongTermRent.values.address = '';
    formikLongTermRent.values.activity = '';
    // formikLongTermRent.values.reg_number = '';
    formikLongTermRent.values.person = '';
    formikLongTermRent.values.mobile = '';
    formikLongTermRent.values.email = '';

  }
  const formikLimozinRequest = useFormik({
    initialValues: {
      type: 1,
      from: '',
      to: '',
      days: 1,
      date: '',
      time: '',
      person: fetchPersonalData?.first_name !== null ? fetchPersonalData.first_name : '',
      mobile: fetchPersonalData?.mobile !== null ? fetchPersonalData.mobile : '',
    },
    validationSchema: validateLimozinRequest,

    onSubmit: async (values) => {
      const fromParam = values.from ? `&from=${values.from}` : '';
      const toParam = values.to ? `&to=${values.to}` : '';
      const date = format(values.date, 'dd/MM/yyyy');

      const time = values.time?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
      const formattedTime = time.replace(/([0-9]{2}:[0-9]{2}).*/, '$1');

      const url = `${process.env.REACT_APP_API_URL}/limozin?type=0${fromParam}${toParam}&days=1&time=${formattedTime}&person=${values.person}&mobile=${values.mobile}&date=${date}`;
      let { data } = await axios.post(url)

      if (data.status) {
        setModalDone(true)
        setTimeout(() => {
          setModalDone(false)
        }, 1500);
        resetFormikLimozinRequest()
      }
    }
  });
  const resetFormikLimozinRequest = () => {
    formikLimozinRequest.values.from = '';
    formikLimozinRequest.values.to = '';
    formikLimozinRequest.values.city = '';
    formikLimozinRequest.values.date = '';
    formikLimozinRequest.values.time = '';

  }
  useEffect(() => {
    formikLimozinRequest.setValues({
      type: 0,
      from: '',
      to: '',
      days: 1,
      date: '',
      time: '',
      person: fetchPersonalData.first_name || '',
      mobile: fetchPersonalData.mobile || '',
    });
  }, [activeSubBtn, fetchPersonalData, formikLimozinRequest.setValues]);



  const formikLimozinRequest8H = useFormik({
    initialValues: {
      days: "",
      date: '',
      time: '',
    },
    validationSchema: validateLimozinRequest8H,

    onSubmit: async (values) => {
      if (values.date !== null && values.days !== null) {
        const endDate = addDays(values.date, values.days - 1);
        setClacDateLim(values.days)
        setStoreStartDate(values.date)
        setStoreEndDate(endDate);
        navigate(`/cars/limousine?find-car-type=limousine`)
        resetFormikLimozinRequest8H()
      } else {
        console.error("يرجى إدخال قيمة صحيحة لعدد الأيام.");
      }

    }
  });
  const resetFormikLimozinRequest8H = () => {
    formikLimozinRequest8H.values.days = '';
    formikLimozinRequest8H.values.date = '';
    formikLimozinRequest8H.values.time = '';
  }
  useEffect(() => {
    formikLimozinRequest8H.setValues({
      days: '',
      date: '',
      time: '',
    });
  }, [activeSubBtn, fetchPersonalData, formikLimozinRequest8H.setValues]);


  return (
    <div dir={isLang === "en" ? 'ltr' : 'rtl'}  >
      <Toast ref={toast} position={isLang === "en" ? 'bottom-center ' : 'bottom-center'} />

      <Container className='app__cars pt-50 pb-50'>
        <div className="flex flex-row justify-content-between">

          <Component.Title title={isLang === "en" ? ' Find Your Car' : ' قم بالبحث عن سيارتك'} />
          <div className="taps_sm">
            <Dropdown
              value={selectedTaps}
              onChange={(e) => handleTapsSelect(e.value)}
              options={taps}
              optionLabel="name"
              placeholder={isLang === "en" ? 'Individuals' : 'افراد '}

            />
          </div> 
        </div>

        <div className="taps__serach">
          <div className="card mt-50">
            <div className="Taps">
              <div className={`btn1 ${activeBtn === 'btn1' ? 'active' : ''}`}>
                <Button severity="warning"
                  onClick={() => {
                    handleBtnClick('btn1')
                    navigate(`/${isLang}?find-car-type=short-term-rent`)
                  }}
                  className="w-8rem" label={isLang === "en" ? 'Individuals' : 'افراد '}
                />
              </div>
              <div className={`btn2 ${activeBtn === 'btn2' ? 'active' : ''}`}>
                <Button severity="warning"
                  onClick={() => {
                    handleBtnClick('btn2')
                    navigate(`/${isLang}?find-car-type=long-term-rent`)
                  }}
                  className="w-8rem"
                  label={isLang === "en" ? 'Companies' : 'الشركــات'} />
              </div>
              {/* <div className={`btn3 ${activeBtn === 'btn3' ? 'active' : ''}`}>
                <Button severity="warning"
                  onClick={() => {
                    handleBtnClick('btn3')
                    navigate(`/?find-car-type=limousine`)
                  }}
                  className="w-8rem"
                  label={isLang === "en" ? 'Limousine' : 'ليموزين '} />
              </div> */}
            </div>
            <div className=" pt-5  px-5 pb-5      ">
              {activeBtn === "btn1" && (
                <div className='Form1'>
                  {/*           <div className="flex  gap-2 align-items-center mb-2">
                    <Checkbox inputId="ingredient1" name="pizza" value="Cheese" onChange={e => setChecked(e.checked)} checked={checked} />
                    <label htmlFor="ingredient1" className="ml-2 text_box">{isLang === "en" ? 'Deliver the car to a different location' : 'تسليم السيارة فى مكان مختلف'}</label>
                  </div> */}
                  <div className="flex gap-2">
                    <form onSubmit={formik.handleSubmit} className='w-100 grid_update_20'>
                      <Row className='w-100'>
                        {/* {
                          !checked &&
                          <Col xl={3} lg={4} md={6} sm={12} className='mt-3'>
                            <Dropdown
                              filter
                              id="branchId"
                              name="branchId"
                              value={formik.values.branchId} // تحديد القيمة المختارة
                              options={branche}
                              optionLabel="name"
                              optionValue="id"
                              onChange={(e) => {
                                formik.setFieldValue('branchId', e.value)
                                setStoreBranches(e.value)
                                setStoreBrancheForm('')

                              }}
                              className="w-100"
                              placeholder={isLang === "en" ? 'Branch' : 'الفرع '}
                            />
                          </Col>
                        } */}
                        {/* {
                          checked &&
                          <>
                            <Col xl={3} lg={4} md={6} sm={12} className='mt-3'>

                              <Dropdown
                                filter
                                id="branchIdFrom"
                                name="branchIdFrom"
                                value={formik.values.branchIdFrom} // تحديد القيمة المختارة
                                options={brancheFrom}
                                optionLabel="name"
                                optionValue="id"
                                onChange={(e) => {
                                  formik.setFieldValue('branchIdFrom', e.value)
                                  setStoreBrancheForm(e.value)
                                  setStoreBranches(null)

                                }}
                                className="w-100"
                                placeholder={isLang === "en" ? 'Pickup Branch ' : 'فرع الاستلام'}
                              />
                            </Col>
                            <Col xl={3} lg={4} md={6} sm={12} className='mt-3'>
                              <Dropdown
                                filter
                                id="brancheTo"
                                name="brancheTo"
                                value={formik.values.branchIdTo} // تحديد القيمة المختارة
                                options={brancheTo}
                                optionLabel="name"
                                optionValue="id"
                                onChange={(e) => {
                                  formik.setFieldValue('branchIdTo', e.value)
                                  setStoreBrancheTo(e.value)
                                }}
                                className="w-100"
                                placeholder={isLang === "en" ? 'Return Branch ' : 'فرع التسليم'}
                              />
                            </Col>
                          </>
                        } */}

                        <Col xl={3} lg={4} md={6} sm={12} className='mt-3'>
                          <Dropdown
                            filter
                            value={formik.values.typeId}
                            id="typeId"
                            name="typeId"
                            optionValue="id"
                            onChange={(e) => {
                              formik.setFieldValue("typeId", e.value)
                              setTypeIdCar(e.value)
                            }}
                            options={carTypes}
                            optionLabel="name"
                            placeholder={isLang === "en" ? 'Car Type' : 'طراز السيارة '}
                            className="w-full  "
                          />
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12} className='mt-3'>
                          <Dropdown
                            filter
                            id="brandId"
                            name="brandId"
                            value={formik.values.brandId}
                            onChange={(e) => {
                              formik.setFieldValue("brandId", e.value)
                              setStoreBrand(e.value)
                              setCarBrandId(e.value)
                            }}
                            options={brands}
                            optionLabel="name"
                            placeholder={isLang === "en" ? 'Car Brand' : 'ماركه السيارة'}
                            className="w-full"
                            optionValue="id"
                          />
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12} dir='ltr' className='mt-3'>
                          <div className={isLang === "en" ? ' ' : '  calendar_styleAr '}>
                            <Calendar
                              id="start"
                              name="start"
                              value={formik.values.start instanceof Date ? formik.values.start : null}
                              placeholder={isLang === "en" ? ' Pickup Date' : '  تاريخ بدأ الإيجار '}
                              onChange={(e) => {
                                formik.setFieldValue("start", e.value)
                                setStoreStartDate(e.value)
                              }}
                              showIcon
                              className='w-full'
                              iconPos={isLang === "en" ? 'right' : 'left'}
                              minDate={new Date()}
                              touchUI
                            />
                            {formik.errors.start && formik.touched.start ?
                              <div dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                <Message severity="error" text={formik.errors.start} />
                              </div> : null
                            }
                          </div>
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12} dir='ltr' className='mt-3'>
                          <div className={isLang === "en" ? ' ' : '  calendar_styleAr '}>
                            <Calendar
                              id="end"
                              name="end"
                              value={formik.values.end instanceof Date ? formik.values.end : null}
                              placeholder={isLang === "en" ? 'Return Date' : 'تاريخ نهايه الإيجار'}
                              onChange={(e) => {
                                formik.setFieldValue("end", e.value);
                                setStoreEndDate(e.value);
                              }}
                              showIcon
                              className='w-full text-center'
                              iconPos={isLang === "en" ? 'right' : 'left'}
                              minDate={
                                formik.values.start && formik.values.start instanceof Date
                                  ? new Date(formik.values.start.getTime()) // 86400000 milliseconds in a day
                                  : new Date()
                              }
                              touchUI
                            />
                            {formik.errors.end && formik.touched.end ?
                              <div dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                <Message severity="error" text={formik.errors.end} />
                              </div> : null
                            }

                          </div>

                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12} className='mt-3 btn__filter-car' >
                          <Button className='w-full' icon="pi pi-search " severity='warning' label={isLang === "en" ? 'Search' : 'بحث  '} />
                        </Col>

                      </Row>

                    </form>

                  </div>
                </div>
              )}
              {
                activeBtn === "btn2" &&
                <div className='Form2'>
                  <form onSubmit={formikLongTermRent.handleSubmit}>
                    <Row className='w-100 flex '>
                      <Col xl={3} lg={4} md={6} sm={12} >
                        <div className="flex flex-column gap-2 mt-3">
                          <label htmlFor="number_cars" >
                            {isLang === "en" ? 'Car Numbers' : 'عدد السيارات '}
                          </label>
                          <InputText
                            id="number_cars"
                            name="number_cars"
                            type="number"
                            value={formikLongTermRent.values.number_cars}
                            onBlur={formikLongTermRent.handleBlur}
                            onChange={formikLongTermRent.handleChange}
                            aria-describedby="username-help"
                            className={`bg-light ${formikLongTermRent.touched.number_cars && formikLongTermRent.errors.number_cars ? 'is-invalid' : ''}`} />
                          {formikLongTermRent.errors.number_cars && formikLongTermRent.touched.number_cars ?
                            <Message severity="error" text={formikLongTermRent.errors.number_cars} /> : null
                          }
                        </div>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12} >
                        <div className="flex flex-column gap-2 mt-3">
                          <label htmlFor="months" >
                            {isLang === "en" ? 'Rental Duration (Month)  ' : '  مدة الايجار (شهر )  '}
                          </label>
                          <InputText
                            id="months"
                            name="months"
                            type="number"
                            value={formikLongTermRent.values.months}
                            onBlur={formikLongTermRent.handleBlur}
                            onChange={formikLongTermRent.handleChange}
                            aria-describedby="username-help" className={`bg-light ${formikLongTermRent.touched.months && formikLongTermRent.errors.months ? 'is-invalid' : ''}`} />
                          {formikLongTermRent.errors.months && formikLongTermRent.touched.months ?
                            <Message severity="error" text={formikLongTermRent.errors.months} /> : null
                          }
                        </div>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12} >
                        <div className="flex flex-column gap-2 mt-3">
                          <label htmlFor="car_brand"> {isLang === "en" ? 'Car Brand' : 'ماركه السيارات'}     </label>
                          {/*                      <InputText
                            id="car_brand"
                            name="car_brand"
                            type="text"
                            value={formikLongTermRent.values.car_brand}
                            onBlur={formikLongTermRent.handleBlur}
                            onChange={formikLongTermRent.handleChange} aria-describedby="username-help" className={`bg-light ${formikLongTermRent.touched.car_brand && formikLongTermRent.errors.car_brand ? 'is-invalid' : ''}`} /> */}
                          <MultiSelect
                            id="car_brand"
                            name="car_brand"
                            value={selectedCities}
                            onChange={(e) => {
                              formikLongTermRent.setFieldTouched('car_brand', true);
                              setSelectedCities(e.value);
                              formikLongTermRent.setFieldValue('car_brand', e.value.length > 0 ? e.value[0].name : ''); // اختر الاسم من العنصر الأول أو اترك السلسلة فارغة إذا لم يتم اختيار شيء
                            }}
                            options={brandsMultiSelect}
                            placeholder={isLang === "en" ? "Select cars" : "اختار السيارة"}
                            optionLabel="name"
                            display="chip"
                            maxSelectedLabels={3}
                            className={`bg-light ${formikLongTermRent.touched.car_brand && formikLongTermRent.errors.car_brand ? 'is-invalid' : ''}`}
                          />

                          {formikLongTermRent.errors.car_brand && formikLongTermRent.touched.car_brand ?
                            <Message severity="error" text={formikLongTermRent.errors.car_brand} /> : null
                          }
                        </div>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12} >
                        <div className="flex flex-column gap-2 mt-3">
                          <label htmlFor="company_name"> {isLang === "en" ? 'Company Name' : '  اسم الشركة '}     </label>
                          <InputText id="company_name"
                            name="company_name"
                            type="text"
                            value={formikLongTermRent.values.company_name}

                            onBlur={formikLongTermRent.handleBlur}
                            onChange={formikLongTermRent.handleChange} aria-describedby="username-help" className={`bg-light ${formikLongTermRent.touched.company_name && formikLongTermRent.errors.company_name ? 'is-invalid' : ''}`} />
                          {formikLongTermRent.errors.company_name && formikLongTermRent.touched.company_name ?
                            <Message severity="error" text={formikLongTermRent.errors.company_name} /> : null
                          }
                        </div>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12} >
                        <div className="flex flex-column gap-2 mt-3">
                          <label htmlFor="city"> {isLang === "en" ? '  City' : ' المدينة     '}     </label>
                          <InputText id="city"
                            name="city"
                            type="text"
                            value={formikLongTermRent.values.city}
                            onBlur={formikLongTermRent.handleBlur}
                            onChange={formikLongTermRent.handleChange} aria-describedby="username-help" className={`bg-light ${formikLongTermRent.touched.city && formikLongTermRent.errors.city ? 'is-invalid' : ''}`} />
                          {formikLongTermRent.errors.city && formikLongTermRent.touched.city ?
                            <Message severity="error" text={formikLongTermRent.errors.city} /> : null
                          }
                        </div>
                      </Col>
                      {/* <Col xl={3} lg={4} md={6} sm={12} >
                        <div className="flex flex-column gap-2  mt-3">
                          <label htmlFor="address"> {isLang === "en" ? '  Address' : ' العنوان     '}     </label>
                          <InputText id="address"
                            name="address"
                            type="address"
                            value={formikLongTermRent.values.address}

                            onBlur={formikLongTermRent.handleBlur}
                            onChange={formikLongTermRent.handleChange} aria-describedby="username-help" className={`bg-light ${formikLongTermRent.touched.address && formikLongTermRent.errors.address ? 'is-invalid' : ''}`} />
                          {formikLongTermRent.errors.address && formikLongTermRent.touched.address ?
                            <Message severity="error" text={formikLongTermRent.errors.address} /> : null
                          }
                        </div>
                      </Col> */}
                      <Col xl={3} lg={4} md={6} sm={12} >
                        <div className="flex flex-column gap-2  mt-3">
                          <label htmlFor="activity"> {isLang === "en" ? '  Activity' : ' النشاط     '}     </label>
                          <InputText id="activity"
                            name="activity"
                            type="text"
                            value={formikLongTermRent.values.activity}

                            onBlur={formikLongTermRent.handleBlur}
                            onChange={formikLongTermRent.handleChange} aria-describedby="username-help" className={`bg-light ${formikLongTermRent.touched.activity && formikLongTermRent.errors.activity ? 'is-invalid' : ''}`} />
                          {formikLongTermRent.errors.activity && formikLongTermRent.touched.activity ?
                            <Message severity="error" text={formikLongTermRent.errors.activity} /> : null
                          }
                        </div>
                      </Col>
                      {/* <Col xl={3} lg={4} md={6} sm={12} >
                        <div className="flex flex-column gap-2  mt-3">
                          <label htmlFor="reg_number"> {isLang === "en" ? '  Commercial Record' : ' سجل تجارى      '}     </label>
                          <InputText id="reg_number"
                            name="reg_number"
                            type="text"
                            value={formikLongTermRent.values.reg_number}

                            onBlur={formikLongTermRent.handleBlur}
                            onChange={formikLongTermRent.handleChange} aria-describedby="username-help" className={`bg-light ${formikLongTermRent.touched.reg_number && formikLongTermRent.errors.reg_number ? 'is-invalid' : ''}`} />
                          {formikLongTermRent.errors.reg_number && formikLongTermRent.touched.reg_number ?
                            <Message severity="error" text={formikLongTermRent.errors.reg_number} /> : null
                          }
                        </div>
                      </Col> */}
                      <Col xl={3} lg={4} md={6} sm={12} >
                        <div className="flex flex-column gap-2  mt-3">
                          <label htmlFor="person"> {isLang === "en" ? '  Name Of Responsible Person  ' : ' اسم الشخص المسؤول '}     </label>
                          <InputText id="person"
                            name="person"
                            type="text"
                            value={formikLongTermRent.values.person}

                            onBlur={formikLongTermRent.handleBlur}
                            onChange={formikLongTermRent.handleChange} aria-describedby="username-help" className={`bg-light ${formikLongTermRent.touched.person && formikLongTermRent.errors.person ? 'is-invalid' : ''}`} />
                          {formikLongTermRent.errors.person && formikLongTermRent.touched.person ?
                            <Message severity="error" text={formikLongTermRent.errors.person} /> : null
                          }
                        </div>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12} >
                        <div className="flex flex-column  mt-3 gap-2">
                          <label htmlFor="mobile"> {isLang === "en" ? 'Phone Number' : '  رقم الجوال '}     </label>
                          <InputText id="mobile"
                            name="mobile"
                            type="text"
                            value={formikLongTermRent.values.mobile}

                            onBlur={formikLongTermRent.handleBlur}
                            onChange={formikLongTermRent.handleChange} aria-describedby="username-help" className={`bg-light ${formikLongTermRent.touched.mobile && formikLongTermRent.errors.mobile ? 'is-invalid' : ''}`} />
                          {formikLongTermRent.errors.mobile && formikLongTermRent.touched.mobile ?
                            <Message severity="error" text={formikLongTermRent.errors.mobile} /> : null
                          }
                        </div>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12} >
                        <div className="flex flex-column   mt-3 gap-2">
                          <label htmlFor="email"> {isLang === "en" ? 'E-mail' : ' البريد الالكترونى '}     </label>
                          <InputText id="email"
                            name="email"
                            type="email"
                            value={formikLongTermRent.values.email}

                            onBlur={formikLongTermRent.handleBlur}
                            onChange={formikLongTermRent.handleChange} aria-describedby="username-help" className={`bg-light ${formikLongTermRent.touched.email && formikLongTermRent.errors.email ? 'is-invalid' : ''}`} />
                          {formikLongTermRent.errors.email && formikLongTermRent.touched.email ?
                            <Message severity="error" text={formikLongTermRent.errors.email} /> : null
                          }
                        </div>
                      </Col>
                      <Col xl={3} lg={4} md={6} sm={12} >
                        <div className="flex flex-column    mt-6 gap-2">
                          <Button type='submit' label={isLang === "en" ? 'Request A Price ' : 'طلب عرض السعر'} />
                        </div>

                      </Col>
                    </Row>
                  </form>
                </div>
              }
              {
                activeBtn === "btn3" &&
                <>
                  <div className={`Taps TapsAr   flex  ${isLang === "en" ? 'justify-content-end' : 'justify-content-start'}`} dir={isLang === "en" ? 'rtl' : 'rtl'}>
                    <div className={`btn4 ${activeSubBtn === 'btn4' ? 'active' : ''}`}>
                      <Button severity="warning" onClick={() => setActiveSubBtn('btn4')} className="w-8rem" label={isLang === "en" ? 'One Way Trip' : 'توصيلة من موقع لآخر'} />
                    </div>
                    <div className={`btn5 ${activeSubBtn === 'btn5' ? 'active' : ''}`}>
                      <Button severity="warning" onClick={() => setActiveSubBtn('btn5')} className="w-8rem" label={isLang === "en" ? 'Full Day (12 hours)' : 'يوم كامل (12 ساعه)'} />
                    </div>
                  </div>

                  {activeSubBtn === "btn4" && (

                    <form onSubmit={formikLimozinRequest.handleSubmit} className='Form1'>
                      <div className="flex mt-3 gap-2">
                        <Row className='w-100'>
                          {/* 'From' Dropdown */}
                          <Col xl={3} lg={4} md={6} sm={12} className='mt-3'>
                            <div className="flex flex-column  ">
                              {/* <Dropdown
                                filter
                                id="from"
                                name="from"
                                value={formikLimozinRequest.values.from}
                                options={LimozinPlacesDataForm}
                                optionLabel="name"
                                optionValue="id"
                                onChange={(e) => {
                                  formikLimozinRequest.setFieldValue('from', e.value)
                                }}
                                onBlur={formikLimozinRequest.handleBlur}
                                placeholder={isLang === "en" ? 'From' : 'مــن'}
                                className="w-full"
                              />
                              {formikLimozinRequest.errors.from && formikLimozinRequest.touched.from ?
                                <div dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                  <Message severity="error" text={formikLimozinRequest.errors.from} />
                                </div> : null
                              } */}
                              <InputText
                                id="from"
                                name="from"
                                value={formikLimozinRequest.values.from}
                                onChange={formikLimozinRequest.handleChange}
                                onBlur={formikLimozinRequest.handleBlur}
                                className='bg-light'
                                placeholder={isLang === "en" ? 'From' : 'مــن'}


                              />
                              {formikLimozinRequest.errors.from && formikLimozinRequest.touched.from ?
                                <div dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                  <Message severity="error" text={formikLimozinRequest.errors.from} />
                                </div> : null
                              }
                            </div>
                          </Col>

                          {/* 'To' Dropdown */}
                          <Col xl={3} lg={4} md={6} sm={12} className='mt-3'>
                            <div className="flex flex-column  ">

                              {/* <Dropdown
                                filter
                                name="to"
                                value={formikLimozinRequest.values.to}
                                onChange={(e) => {
                                  formikLimozinRequest.setFieldValue('to', e.value)
                                }}
                                onBlur={formikLimozinRequest.handleBlur}
                                options={LimozinPlacesDataTo}
                                optionLabel="name"
                                optionValue="id"
                                placeholder={isLang === "en" ? 'To' : 'إلـــي'}
                                className="w-full"
                              />
                              {formikLimozinRequest.errors.to && formikLimozinRequest.touched.to ?
                                <div dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                  <Message severity="error" text={formikLimozinRequest.errors.to} />
                                </div> : null
                              } */}

                              <InputText
                                id="to"
                                name="to"
                                value={formikLimozinRequest.values.to}
                                onChange={formikLimozinRequest.handleChange}
                                onBlur={formikLimozinRequest.handleBlur}
                                className='bg-light'
                                placeholder={isLang === "en" ? 'To' : 'إلـــي'}

                              />
                              {formikLimozinRequest.errors.to && formikLimozinRequest.touched.to ?
                                <div dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                  <Message severity="error" text={formikLimozinRequest.errors.to} />
                                </div> : null
                              }
                            </div>
                          </Col>

                          {/* 'Date' Calendar */}
                          <Col xl={3} lg={4} md={6} sm={12} className='mt-3' dir='ltr'>
                            <div className="flex flex-column  ">

                              <div className={isLang === "en" ? ' ' : 'calendar_styleAr'}>
                                <Calendar
                                  name="date"
                                  value={formikLimozinRequest.values.date}
                                  onChange={(e) => {
                                    formikLimozinRequest.setFieldValue("date", e.value);
                                    if (new Date(formikLimozinRequest.values.date)) {
                                      formikLimozinRequest.setFieldValue("time", new Date())
                                    }
                                  }}
                                  placeholder={isLang === "en" ? 'Rental Start Date' : 'تاريخ بدأ الإيجار'}
                                  showIcon
                                  className='w-full'
                                  iconPos={isLang === "en" ? 'right' : 'left'}
                                  minDate={new Date()}
                                  touchUI
                                />

                                {formikLimozinRequest.errors.date && formikLimozinRequest.touched.date ?
                                  <div dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                    <Message severity="error" text={formikLimozinRequest.errors.date} />
                                  </div> : null
                                }
                              </div>
                            </div>
                          </Col>

                          {/* 'Hour' Calendar */}
                          <Col xl={3} lg={4} md={6} sm={12} className='mt-3' dir='ltr'>
                            <div className="flex flex-column gap-2">

                              <div className={isLang === "en" ? ' ' : 'calendar_styleAr'}>
                                <Calendar
                                  name="time"
                                  value={formikLimozinRequest.values.time}
                                  onChange={(e) => {
                                    formikLimozinRequest.setFieldValue("time", e.value);
                                  }}
                                  placeholder={isLang === "en" ? 'Rental Start Time' : 'وقت بداية الإيجار'}
                                  showIcon
                                  showTime={true}
                                  hourFormat="24"
                                  className='w-full'
                                  dir="ltr"
                                  timeOnly
                                  showPeriod={true} // تمكين تغيير AM و PM
                                  iconPos={isLang === "en" ? 'right' : 'left'}
                                  icon="pi pi-clock"
                                  minDate={new Date(formikLimozinRequest.values.date)} // تحديد الوقت الحالي كحد أدنى

                                />
                                {formikLimozinRequest.errors.time && formikLimozinRequest.touched.time ?
                                  <div div dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                    <Message severity="error" text={formikLimozinRequest.errors.time} />
                                  </div> : null
                                }
                              </div>
                            </div>
                          </Col>

                          {/* 'Name' InputText */}
                          <Col xl={3} lg={4} md={6} sm={12} className='mt-3'>
                            <div className="flex flex-column  ">

                              <InputText
                                id="person"
                                name="person"
                                placeholder={isLang === "en" ? 'Full name' : 'الاسم كامل '}

                                value={formikLimozinRequest.values.person}
                                onChange={formikLimozinRequest.handleChange}
                                onBlur={formikLimozinRequest.handleBlur}
                                className='bg-light'
                              />
                              {formikLimozinRequest.errors.person && formikLimozinRequest.touched.person ?
                                <div dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                  <Message severity="error" text={formikLimozinRequest.errors.person} />
                                </div> : null
                              }

                            </div>
                          </Col>

                          {/* 'Mobile' InputText */}
                          <Col xl={3} lg={4} md={6} sm={12} className='mt-3'>
                            <div className="flex flex-column  ">

                              <InputText
                                id="mobile"
                                name="mobile"
                                placeholder={isLang === "en" ? 'Mobile' : ' رقم الجوال '}
                                value={formikLimozinRequest.values.mobile}
                                onChange={formikLimozinRequest.handleChange}
                                onBlur={formikLimozinRequest.handleBlur}
                                className='bg-light'
                              />
                              {formikLimozinRequest.errors.mobile && formikLimozinRequest.touched.mobile ?
                                <div dir={isLang === "en" ? 'ltr' : 'rtl'} >
                                  <Message severity="error" text={formikLimozinRequest.errors.mobile} />
                                </div> : null
                              }
                            </div>
                          </Col>

                          {/* Submit Button */}
                          <Col xl={3} lg={4} md={6} sm={12}>
                            <div className="flex flex-column  mt-3 gap-2">
                              <Button
                                label={isLang === "en" ? 'Request A Price ' : 'طلب عرض السعر'}
                                className='w-100'
                                type="submit"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </form>

                  )}
                  {activeSubBtn === "btn5" && (

                    <form onSubmit={formikLimozinRequest8H.handleSubmit} className='Form3'>
                      <div className="flex mt-3 gap-2">
                        <Row className='w-100'>
                          {/* 'Number of Days' InputText */}
                          <Col xl={3} lg={4} md={6} sm={12} className='mt-3'>
                            <div className="flex flex-column  ">

                              <InputText
                                id="days"
                                name="days"
                                type="number"
                                value={formikLimozinRequest8H.values.days}
                                onChange={formikLimozinRequest8H.handleChange}
                                onBlur={formikLimozinRequest8H.handleBlur}
                                className='bg-light'
                                placeholder={isLang === "en" ? 'Enter days' : 'حدد عدد الايام'}
                              />
                              {formikLimozinRequest8H.errors.days && formikLimozinRequest8H.touched.days ?
                                <div div dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                  <Message severity="error" text={formikLimozinRequest8H.errors.days} />
                                </div> : null
                              }
                            </div>
                          </Col>

                          {/* 'Date' Calendar */}
                          <Col xl={3} lg={4} md={6} sm={12} className='mt-3' dir='ltr'>
                            <div className="flex flex-column gap-2">

                              <div className={isLang === "en" ? ' ' : 'calendar_styleAr'}>
                                <Calendar
                                  name="date"
                                  value={formikLimozinRequest8H.values.date}
                                  onChange={(e) => {
                                    formikLimozinRequest8H.setFieldValue("date", e.value);
                                    if (new Date(formikLimozinRequest8H.values.date)) {
                                      formikLimozinRequest8H.setFieldValue("time", new Date())
                                    }
                                  }}
                                  placeholder={isLang === "en" ? 'Rental Start Date' : 'تاريخ بدأ الإيجار'}
                                  showIcon
                                  className='w-full'
                                  iconPos={isLang === "en" ? 'right' : 'left'}
                                  minDate={new Date()}
                                  touchUI
                                />
                                {formikLimozinRequest8H.errors.date && formikLimozinRequest8H.touched.date ?
                                  <div div dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                    <Message severity="error" text={formikLimozinRequest8H.errors.date} />
                                  </div> : null
                                }
                              </div>
                            </div>
                          </Col>

                          {/* 'Hour' Calendar */}
                          <Col xl={3} lg={4} md={6} sm={12} className='mt-3' dir='ltr'>
                            <div className="flex flex-column gap-2">

                              <div className={isLang === "en" ? ' ' : 'calendar_styleAr'}>
                                <Calendar
                                  name="time"
                                  value={formikLimozinRequest8H.values.time}
                                  onChange={(e) => {
                                    formikLimozinRequest8H.setFieldValue("time", e.value);
                                    setStoreTime(e.value)
                                  }}
                                  placeholder={isLang === "en" ? 'Pickup Time' : 'وقت بداية الإيجار'}

                                  showIcon
                                  showTime={true}
                                  hourFormat="24"
                                  className='w-full'
                                  dir="ltr"
                                  timeOnly
                                  showPeriod={true} // تمكين تغيير AM و PM
                                  iconPos={isLang === "en" ? 'right' : 'left'}
                                  icon="pi pi-clock"
                                  minDate={new Date(formikLimozinRequest8H.values.date)} // تحديد الوقت الحالي كحد أدنى

                                />
                                {formikLimozinRequest8H.errors.time && formikLimozinRequest8H.touched.time ?
                                  <div div dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                    <Message severity="error" text={formikLimozinRequest8H.errors.time} />
                                  </div> : null
                                }
                              </div>
                            </div>
                          </Col>

                          {/* 'Name' InputText */}
                          {/*            <Col xl={3} lg={4} md={6} sm={12} className='mt-3'>
            <div className="flex flex-column  ">

                <InputText
                    id="person"
                    name="person"
                    value={formikLimozinRequest8H.values.person}
                    onChange={formikLimozinRequest8H.handleChange}
                    onBlur={formikLimozinRequest8H.handleBlur}
                    placeholder={isLang === "en" ? 'Full name' : 'الاسم كامل '}
                    className='bg-light'
                />
                {formikLimozinRequest8H.errors.person && formikLimozinRequest8H.touched.person ?
                    <div div dir={isLang === "en" ? 'ltr' : 'rtl'}>
                        <Message severity="error" text={formikLimozinRequest8H.errors.person} />
                    </div> : null
                }
            </div>
        </Col> */}

                          {/* 'Mobile' InputText */}
                          {/*              <Col xl={3} lg={4} md={6} sm={12} className='mt-3'>
            <div className="flex flex-column ">

                <InputText
                    id="mobile"
                    name="mobile"
                    value={formikLimozinRequest8H.values.mobile}
                    onChange={formikLimozinRequest8H.handleChange}
                    onBlur={formikLimozinRequest8H.handleBlur}
                    className='bg-light'
                    placeholder={isLang === "en" ? '  mobile' : '  رقم الجوال '}

                />
                {formikLimozinRequest8H.errors.mobile && formikLimozinRequest8H.touched.mobile ?
                    <div div dir={isLang === "en" ? 'ltr' : 'rtl'}>
                        <Message severity="error" text={formikLimozinRequest8H.errors.mobile} />
                    </div> : null
                }
            </div>
        </Col> */}

                          {/* Submit Button */}
                          <Col xl={3} lg={4} md={6} sm={12}>
                            <div className="flex flex-column mt-3 gap-2">
                              <Button
                                label={isLang === "en" ? 'Limousine Search' : 'بحث الليموزين'}
                                className='w-100'
                                type="submit"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </form>

                  )}
                </>
              }


            </div>
          </div>
        </div>
      </Container>
      <Modal
        show={modalDone}
        onHide={() => setModalDone(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ height: '100vh' }} className='flex justify-content-center align-item-center '>
          <div style={{ width: '400px' }} className='h-100  flex justify-content-center align-item-center flex-column '>
            <img src={img.Image_Reservations} alt="لوجو حجوزات" loading='lazy' />

            <h2 className='text-center text_done_Reservations'>    {isLang === "en" ? 'The order has been confirmed' : 'تم تاكيد الطلب'}</h2>

          </div>
        </Modal.Body>

      </Modal>
    </div>
  )
}

export default SearchCar
