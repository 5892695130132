import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Component from '../../../constants/Component';
import { SharedData } from '../../../context/CallApi';
import Slider from './Slider/Slider';
import { Helmet } from 'react-helmet-async';
import { LocalizationContext } from '../../../context/LangChange';


const Home = () => {

  let { isLang } = useContext(LocalizationContext);

  let { bannersHome, carsSliderHome, offersHome, branchesHome, carBrandsHome } = useContext(SharedData)
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem('path', currentPath)
    window.scrollBy(0, -window.pageYOffset);
  }, [])
  let { seo, fetchSEO } = useContext(SharedData);
  useEffect(() => {
    window.scrollBy(0, -window.pageYOffset);
    fetchSEO('HOME')
  }, [])
  return (

    <div>
      <Helmet >
        <meta name="title" content={seo?.title} />
        <meta name="description" content={seo?.desc} />
        <meta name="keywords" content={seo?.keywords} />
        <title>{isLang == "en" ? 'Alghazal' : 'الغزال لتأجير السيارات'} </title>
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo?.desc} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Slider dataHome={bannersHome} />
      <Component.SearchCar branchesHome={branchesHome} carBrandsHome={carBrandsHome} />
      <Component.LatestCars carsSliderHome={carsSliderHome} />
      <Component.DownloadAppNow />
      {/* {
        offersHome?.length >= 1 &&
        <Component.HomeOffers dataHome={offersHome} />
      } */}
      <Component.HomeContactUS />
      <Component.BlogSection />
      <Component.Services />
      {/* <Component.Loader /> */}

    </div>
  )
}

export default Home
