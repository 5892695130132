import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './style.scss';
import { LocalizationContext } from '../../../context/LangChange';
import { format, isValid, parse } from 'date-fns';
import { arSA, enUS } from 'date-fns/locale';
import { Helmet } from 'react-helmet-async';

const BlogsDetails = () => {
    let { id } = useParams()
    let { isLang } = useContext(LocalizationContext);

    const [data, setBlogPosts] = useState([]);
    const fetchData = async () => {
        let data = await axios.get(`${process.env.REACT_APP_API_URL}/blog_details?id=${id}`)
         setBlogPosts(data?.data);

    }
    useEffect(() => {
        fetchData()
    }, []);

    const parseDateString = (dateString) => {
        return parse(dateString, 'dd/MM/yyyy', new Date());
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{isLang === "en" ? `${data?.title} | Alghazal ` : `   ${data?.title} | الغزال لتأجير السيارات`}</title>

                <meta name="title" content={data?.title} />
                <meta name="description" content={data?.meta_desc} />
                <meta name="keywords" content={data?.meta_keys} />
                <meta name="author" content={data?.author} />
                <meta property="og:title" content={data.title} />
                <meta property="og:description" content={data?.meta_desc} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className="app_blog_details_img">
                <img src={data.image} alt="Office Setting" className='' />
               <div className="overlay"></div>
            </div>
            <div className="app_blog_details"> 
                <section className="introduction" dir='rtl'> 
                    <div className="flex blog_meta justify-content-between mt-4 "> 
                        <div className="  d-flex gap-3 align-items-center">
                            <i className='pi pi-calendar'></i>
                            {isValid(parseDateString(data.date)) ? (
                                <span className='blog_date '>
                                    {isLang === 'ar'
                                        ? format(parseDateString(data.date), 'dd MMMM yyyy', { locale: arSA })
                                        : format(parseDateString(data.date), 'MMMM dd, yyyy', { locale: enUS })
                                    }
                                </span>
                            ) : (
                                <span>Invalid date</span>
                            )}
                        </div>
                        <h6>{data.author}</h6>
                    </div>
                </section>
                <div className="title_blog" dir={isLang == "en" ? "ltr" : 'rtl'}>
                    <h1>{data?.title}</h1>
                </div>

                <section className=" conclusion_content" dir={isLang == "en" ? "ltr" : 'rtl'}>
                    <div dangerouslySetInnerHTML={{ __html: data?.content }} />
                </section>

                {
                    data?.tags &&
                    <section className=" tabs mt-5" dir={isLang == "en" ? "ltr" : 'rtl'}>
                        <i>{data?.tags}</i>
                    </section>
                }

            </div>
        </>
    );
};

export default BlogsDetails;
