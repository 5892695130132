import React, { useContext } from 'react';
import { LocalizationContext } from '../../context/LangChange';

const Title = ({ title, headerSeo }) => {
  let { isLang } = useContext(LocalizationContext);

  return (
    <div className='app__title' dir={isLang === "en" ? 'ltr' : 'rtl'}>
      <div style={{ height: '45px' }} >
        <div className='after'></div>
      </div>
      {
        headerSeo == "h1" ?
          <h1>{title}</h1> :
          <h2>{title}</h2>
      }
    </div>
  )
}

export default Title
