import axios from 'axios'
import { format, parse } from 'date-fns'
import { useFormik } from 'formik'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Carousel } from "primereact/carousel"
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Message } from 'primereact/message'
import { Messages } from 'primereact/messages'
import { Toast } from 'primereact/toast'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import LocationGoogleMap from '../../../GoogleMap/LocationGoogleMap'
import Component from '../../../constants/Component'
import Icons from '../../../constants/Icons'
import img from '../../../constants/Img'
import { SharedData } from '../../../context/CallApi'
import { FiltersApis } from '../../../context/CarFiltersApis'
import { CarFilterStates } from '../../../context/FiltersCars'
import { LocalizationContext } from '../../../context/LangChange'
import { Helmet } from 'react-helmet-async'


const PaymentDetails = () => {
    let { isLang } = useContext(LocalizationContext);
    let { bookingValidStep1, fetchPersonal, fetchPersonalData, clientStatus } = useContext(SharedData);
    let { branchesData } = useContext(FiltersApis);
    let { setStoreBrancheTo, storeStartDate, clacDateLim, storeBrancheTo, setStoreBrancheForm,
        storeBrancheFrom, storeEndDate, userLocationMap, setLoctionMap } = useContext(CarFilterStates);
    const [showMaintenance, setShowMaintenance] = useState(null);
    const handleCloseMaintenance = () => setShowMaintenance(false);
    let { id } = useParams()
    let navigate = useNavigate()

    const toast = useRef(null);
    const msgs = useRef(null);
    const msgs2 = useRef(null);
    const fetchPersonalRef = useRef(fetchPersonal);

    const location = useLocation();

    const [DayOfWeekStart, setDayOfWeekStart] = useState(null);
    const [show, setShow] = useState(null);
    const [DayOfWeekEnd, setDayOfWeekEnd] = useState(null);
    const handleClose = () => setShow(false);
    const [checkDay, setCheckDay] = useState(true)
    const [checkAfterStartDay, setCheckDayAfterStartDay] = useState(true)
    const [checkAfterStartDayValue, setCheckDayAfterStartDayValue] = useState(0)
    const [Days, setCheckDayNum] = useState(0)

    const validationSchemaPay = Yup.object().shape({
        type: Yup.string().required(isLang === "en" ? 'Pickup location required' : 'مكان الاستلام مطلوب'),
        payType: Yup.string().required(isLang === "en" ? 'Payment Method is required' : "طريقة الدفع مطلوبة"),
        driver: Yup.boolean().required(isLang === "en" ? 'Please select yes or no' : 'يرجى اختيار نعم أو لا'),
        authorized: Yup.boolean().required(isLang === "en" ? 'Please select yes or no' : 'يرجى اختيار نعم أو لا'),
        startTime: Yup.date().nullable(),
        endTime: Yup.date().nullable(),

    });
    const validate = (values) => {
        const errors = {};
        if (values.endDate < values.startDate) {
            errors.endDate = isLang === "en" ? 'End date must be equal to or after the start date' : "يجب أن يكون تاريخ الانتهاء مساويًا لتاريخ البدء أو بعده";
        }
        return errors;
    };


    useEffect(() => {
        fetchPersonalRef.current();
    }, []);

    useEffect(() => {
        if (clientStatus == 1) {
            localStorage.removeItem("client_id");
            localStorage.removeItem("CLIENT_FOUND");
            navigate('/auth/sign-in')
        }
    }, [clientStatus]);





    const [fetchCities, setFetchCities] = useState([]);
    const fetchCitiesData = async () => {
        const url = `${process.env.REACT_APP_API_URL}/cities?type=1`;
        let { data } = await axios.get(url)
        setFetchCities(data);
    }

    const [getReservationsData, setGetReservationsData] = useState(null);
    const brancheFrom = [...branchesData.map((city) => ({
        name: isLang === "en" ? city.name : city.arabic_name,
        id: city.id,
        city_id: city.city_id,
    }))];
    const brancheTo = [...branchesData.map((city) => ({
        name: isLang === "en" ? city.name : city.arabic_name,
        id: city.id,
        city_id: city.city_id,
    }))];
    const payType = [
        { name: isLang === "en" ? "Cash" : "نقـــداً", value: 0 },
        { name: isLang === "en" ? "Card" : "بطاقة", value: 1 },
    ];
    const delivery = [
        { name: isLang === "en" ? "Receipt from branch" : "استلام من الفرع", value: 0 },
        { name: isLang === "en" ? "Deliever to client" : "تسليم لمقر العميل ", value: 1 },
    ];
    const [deliveryType, setDelivery] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    // const [dtnLoading, setBtnLoading] = useState(false);
    const [CalcDays, setCalcDays] = useState(false);
    const [detailsData, setDetailsData] = useState([]);

    const fetchCarsDetails = async () => {
        const url = `${process.env.REACT_APP_API_URL}/cardetails?carId=${id}`;
        let { data } = await axios.get(url)
        setDetailsData(data);
    }

    const fetchCarsDetailsRef = useRef(fetchCarsDetails);
    const fetchCitiesDataRef = useRef(fetchCitiesData);
    useEffect(() => {
        window.scrollBy(0, -window.pageYOffset);
    }, [])
    useEffect(() => {
        fetchCarsDetailsRef.current();
    }, [id]);

    useEffect(() => {
        fetchCitiesDataRef.current();
    }, [])

    const getValidationSchema = (deliveryType) => {
        if (deliveryType === 0) {
            // When deliveryType is 0, no validation on cityId and address
            return Yup.object().shape({
                ...validationSchemaPay.fields,
                branchId: Yup.string().required(isLang === "en" ? 'Pickup Branch is required' : 'فرع الاستلام مطلوب '),
                deliveringId: Yup.string().required(isLang === "en" ? 'Return  branch is required' : 'فرع التسليم مطلوب '),
            });
        } else {
            // هتعمل هنا حساب عدد الكيلو مترات علشان تحسب سعر التوصبل

            // When deliveryType is 1, apply validation on branchId
            return Yup.object().shape({
                ...validationSchemaPay.fields,
                cityId: Yup.string().required(isLang === "en" ? 'City is required' : 'مدينة مطلوبة'),
                address: Yup.string().required(isLang === "en" ? 'Address is required' : 'العنوان مطلوب'),
            });
        }
    };
    const [overKM, setOVER_KM] = useState(true)

    const [deliveryFeesData, setDeliveryFeesData] = useState(null)
    useEffect(() => {
        if (deliveryType === 1) {
            if (userLocationMap) {
                deliveryfees(userLocationMap)
            }
        }
        if (deliveryType == 0) {
            setLoctionMap(null)
            setOVER_KM(true)
        }
    }, [userLocationMap, deliveryType, overKM])
    const deliveryfees = async (location) => {
        const url = `${process.env.REACT_APP_API_URL}/deliveryfees?car_id=${id}&lat=${location.lat}&lang=${location.lng}`;
        let { data } = await axios.get(url)
        if (data.status === true) {
            setOVER_KM(true)
            setDeliveryFeesData(data)
        } else {
            setOVER_KM(false)
        }

    }
    useEffect(() => {
        if (modalShow === false) {
            setOVER_KM(true)
        }
    }, [modalShow])
    const formikValidationSchema = getValidationSchema(deliveryType);
    const [errorTime, setErrorTime] = useState(false)
    const [errorEndTime, setErrorEndTime] = useState(false)


    const formikReservationsApi = useFormik({
        initialValues: {
            carId: Number(id),
            clientId: Number(localStorage.getItem('client_id')),
            startDate: '',
            endDate: '',
            cityId: '',
            address: '',
            branchId: storeBrancheFrom !== null ? storeBrancheFrom : '',
            deliveringId: storeBrancheTo !== null ? storeBrancheTo : '',
            type: '',
            payType: '',
            promo: '',
            tax: 0.15,
            total: '',
            days: '',
            driver: 1,
            authorized: 0,
            startTime: '',
            endTime: '',
        },
        validationSchema: formikValidationSchema, // Pass the dynamically created schema
        validate,
        onSubmit: async (values) => {
            let cat_id = detailsData?.cat_id;
            let id_ype = fetchPersonalData?.id_ype;
            let nationalId = fetchPersonalData?.nationalId?.charAt(0);
            if (localStorage.getItem('type_national_id') == 0) {
                if (errorTime === true || errorEndTime === true) {
                    setShow(true)
                } else {
                    if (formikReservationsApi.isValid) {

                        let token = localStorage.getItem('client_id')
                        if (!token) {
                            localStorage.setItem('openLogin', 'open')
                            navigate('/auth/sign-in')
                        } else {
                            setModalShow(true)
                        }
                    } else {
                        setModalShow(false)
                    }
                    setGetReservationsData(values);
                }
            } else {

                if (cat_id == 5 && (nationalId == '2' || nationalId == '0' || nationalId == '' || nationalId == null)) {
                    setShowMaintenance(true)
                } else {
                    if (errorTime === true || errorEndTime === true) {
                        setShow(true)
                    } else {
                        if (formikReservationsApi.isValid) {

                            let token = localStorage.getItem('client_id')
                            if (!token) {
                                localStorage.setItem('openLogin', 'open')
                                navigate('/auth/sign-in')
                            } else {
                                setModalShow(true)
                            }
                        } else {
                            setModalShow(false)
                        }
                        setGetReservationsData(values);
                    }
                }
            }
        }
    });

    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const findCarTypeParam = urlSearchParams.get('car-lim');

        if (findCarTypeParam == 1) {
            setCalcDays(clacDateLim)
            setSelectedStartDate(format(new Date(storeStartDate), 'dd/MM/yyyy'))
            setSelectedEndDate(format(new Date(storeEndDate), 'dd/MM/yyyy'))

        } else {
            if (storeStartDate !== null && storeEndDate !== null) {
                clacDate(format(storeStartDate, 'dd/MM/yyyy'), format(storeEndDate, 'dd/MM/yyyy'));
                setSelectedStartDate(format(storeStartDate, 'dd/MM/yyyy'))
                setSelectedEndDate(format(storeEndDate, 'dd/MM/yyyy'))
            }

        }

    }, [])


    const fetchSittingData = async () => {
        const url = `${process.env.REACT_APP_API_URL}/settingsservlet`;
        let { data } = await axios.get(url)

        return data
    }
    const handleStartDateChange = async (start, time) => {
        // Ensure 'start' is a Date object
        setDayOfWeekStart(start.getDay())
        const startDate = new Date(start);
        await fetchSittingData().then((data) => {
            // Check if the input date is valid
            if (isNaN(startDate.getTime())) {
                console.error("Invalid date");
                return;
            }

            // Format the date for display or further use
            const startDateStr = format(startDate, 'dd/MM/yyyy');
            setSelectedStartDate(startDateStr);

            // Get today's date and set the time to midnight for accurate comparison
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            // Calculate the difference in days
            const diffInTime = startDate.getTime() - today.getTime();
            const diffInDays = diffInTime / (1000 * 3600 * 24);
            // Check if the difference is more than 10 days
            if (diffInDays >= data?.STOP_RES_AFTER) {
                setCheckDayAfterStartDay(false)
                setCheckDay(true)
                setCheckDayAfterStartDayValue(data?.STOP_RES_AFTER)
            } else {
                setCheckDayAfterStartDay(true)
                setCheckDay(true)
            }
        })
    };


    const handleEndDateChange = (end) => {
        setDayOfWeekEnd(end.getDay())

        const endDateStr = format(end, 'dd/MM/yyyy');
        setSelectedEndDate(endDateStr)
        // fetchReservationCheckApi(selectedStartDate, endDateStr);
        clacDate(selectedStartDate, endDateStr);
    };
    useEffect(() => {
        formikReservationsApi.setFieldValue('startDate', storeStartDate);
        formikReservationsApi.setFieldValue('endDate', storeEndDate);
    }, [])
    // Promo
    const [promoValue, setPromoValue] = useState(null);
    const [promoType, setPromoType] = useState(null);
    const [promoCodeValue, setPromoCodeValue] = useState(null);
    const [promoCheck, setPromoCheck] = useState(false);
    const [promoCheck1, setPromoCheck1] = useState(false);
    const [handelpromoCheck, sethandelPromoCheck] = useState(false);
    const promoCodeValid = () => {
        toast.current.show({ severity: 'success', summary: 'ًصحيح', detail: 'برومو كود  فعال' });
    };
    const promoCodeCheckInValid = () => {
        toast.current.show({ severity: 'error', summary: 'خطــا', detail: 'برومو كود غير  فعال ' });
    };
    const promoCodeCheckApi = async (promo) => {
        toast.current.clear();
        setPromoValue(null)
        setPromoType(null)
        setPromoCodeValue(null)
        setPromoCheck(false)
        setPromoCheck1(false)
        sethandelPromoCheck(false)
        if (promo) {
            toast.current.clear();
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/promocheck?promo=${promo}&start=${selectedStartDate}&end=${selectedEndDate}`);
                sethandelPromoCheck(true)
                if (data.status) {
                    setPromoCheck(false)
                    setPromoCheck1(true)
                    setPromoCodeValue(promo)
                    promoCodeValid()
                    if (data.type === 0) {
                        setPromoType(0)
                        setPromoValue(data?.value)
                    } else if (data.type === 1) {
                        setPromoValue(data?.value)
                        setPromoType(1)
                    }
                } else {
                    setPromoCheck(true)
                    setPromoCheck1(false)
                    setPromoValue('')
                    promoCodeCheckInValid()
                    setPromoCodeValue(null)

                }
            } catch (error) {
                console.error('Network error:', error);
            }

        }
    };


    const clacDate = (start, end) => {
        const startDate = parse(start, 'dd/MM/yyyy', new Date());
        const endDate = parse(end, 'dd/MM/yyyy', new Date());
        // حساب الفرق بين التواريخ بالأيام
        const timeDifference = Math.abs(endDate - startDate);
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
        fetchCheckDays(daysDifference === 0 ? daysDifference + 1 : daysDifference);
        setCalcDays(daysDifference === 0 ? daysDifference + 1 : daysDifference)

    }
    async function fetchCheckDays(days) {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/checkdays`)
        if (data?.check == true) {
            if (days >= data?.days) {
                setCheckDay(true)
                setCheckDayNum(data?.days);
            } else {
                setCheckDay(false)
                checksDayMax()
            }
        } else {

            setCheckDay(false)
            checksDayMax()
        }

    }
    const checksDayMax = () => {
        if (msgs2.current) {
            msgs2.current.clear();
            msgs2.current.show([
                { severity: 'warn', summary: 'Warning', detail: 'Message Content', sticky: true, closable: false },
            ]);

        }
    }
    const responsiveOptions = [
        {
            breakpoint: "1024px",
            numVisible: 3,
            numScroll: 3,
        },
        {
            breakpoint: "600px",
            numVisible: 2,
            numScroll: 2,
        },
        {
            breakpoint: "480px",
            numVisible: 1,
            numScroll: 1,
        },
    ];

    useEffect(() => {
        localStorage.setItem('res_path', location.pathname + location.search)
    }, [])


    useEffect(() => {
        const currentPath = location.pathname;

        if (currentPath === `/cd/${id}`) {
            fetchOfferCheck()
        }
    }, [])
    const fetchOfferCheck = async () => {
        const url = `${process.env.REACT_APP_API_URL}/wlog?oid=${id}&type=0`;
        let { data } = await axios.post(url)
        if (data?.status === true) {
            navigate(`/cars/${id}`)
        }
    }

    const changeEndDateAndTime = async () => {
        if (new Date(storeEndDate instanceof Date ? storeEndDate : formikReservationsApi.values.endDate)) {
            formikReservationsApi.setFieldValue("endTime", new Date())
            await validateTime(new Date(), 'end');
        }
    }

    const changeStartDateAndTime = async () => {
        if (new Date(storeStartDate instanceof Date ? storeStartDate : formikReservationsApi.values.startDate)) {
            await formikReservationsApi.setFieldValue("startTime", new Date())
            await validateTime(new Date(), 'start');
        }
    }
    const validateTime = (time, typeTime) => {

        const selectedTime = time.getHours() + (time.getMinutes() / 60); // Convert minutes to fraction of an hour

        let isValidTime;
        if (typeTime === 'end') {
            if (DayOfWeekEnd === 5) {
                isValidTime = selectedTime >= 16.5 && selectedTime < 22; // Time range for Friday
            } else {
                isValidTime = selectedTime >= 8.5 && selectedTime < 22.5; // Time range for other days
            }
        }
        if (typeTime === 'start') {
            if (DayOfWeekStart === 5) {
                isValidTime = selectedTime >= 16.5 && selectedTime < 22; // Time range for Friday
            } else {
                isValidTime = selectedTime >= 8.5 && selectedTime < 22.5; // Time range for other days
            }

        }

        if (!isValidTime) {
            if (typeTime === 'end') {
                setErrorEndTime(true);
            } else if (typeTime === 'start') {
                setErrorTime(true);
            }
        } else {
            if (typeTime === 'end') {
                setErrorEndTime(false);
            } else if (typeTime === 'start') {
                setErrorTime(false);
            }
        }
    };

    let { seo, fetchSEO } = useContext(SharedData);
    useEffect(() => {
        fetchSEO('CARS')

    }, [])
    return (
        <>
            <Helmet >
                <title>{isLang === "en" ? 'Fleet | Alghazal' : 'أسطـول الغزال'}</title>
                <meta name="title" content={seo?.title} />
                <meta name="description" content={seo?.desc} />
                <meta name="keywords" content={seo?.keywords} />
                <meta property="og:title" content={seo.title} />
                <meta property="og:description" content={seo?.desc} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Toast ref={toast} position="bottom-center" />
            <Container>
                <Row className='mt-5 pt-6' dir={isLang === "en" ? 'ltr' : 'rtl'}>
                    <Col xl={7} lg={7} md={12} sm={12} className='mt-7' >
                        <Component.Title title={`${isLang === "en" ? detailsData.brand : detailsData.brand_arabic}` + '  ' + `${isLang === "en" ? detailsData.sub_brand : detailsData.sub_brand_arabic}`} />
                        <div className="slider_card mt-40 " dir={isLang === "en" ? 'ltr' : 'rtl'}>
                            <ul className='mt-'>
                                <li >
                                    <Icons.SliderCarSedan />
                                    <strong className='bold'>{detailsData.year} {' '} {isLang === "en" ? detailsData.type : detailsData.type_arabic}  </strong>
                                </li>
                                <li className='mt-2'>
                                    <Icons.CarRent />
                                    {/* <span>{isLang === "en" ? 'Limousine' : 'ليموزين'}</span> : */}
                                    <span>{isLang === "en" ? 'Individuals' : 'افراد '}</span>
                                </li>
                                <li className='mt-2'>
                                    <Icons.SliderCarMoney />
                                    {detailsData.offer !== 0 && <span className='app__discount'>{detailsData.price} {isLang === "en" ? 'SR' : 'ريال'} </span>}
                                    {
                                        detailsData.offer === 0 ?
                                            <span>{detailsData.price} {isLang === "en" ? 'SR' : 'ريال'}</span>
                                            :
                                            <span>{detailsData.offer_price} {isLang === "en" ? 'SR' : 'ريال'}</span>
                                    }
                                    <span className='oneDay mr-4' >  {isLang === "en" ? 'Per day/tax included' : 'لليوم / شامل الضريبة'}</span>
                                </li>
                                <li className='mt-2'>
                                    <Icons.SliderCarPeople />
                                    <span>{detailsData.pasengers} {isLang === "en" ? 'Pasengers' : 'راكب'}   </span>
                                </li>
                                {detailsData.aircondition !== 0 && <li className='mt-2'>
                                    <Icons.SliderCarSoil />
                                    <span>{isLang === "en" ? 'Aircondition' : 'مكيفة'} </span>
                                </li>}

                                {
                                    detailsData.att3 &&
                                    <li className='mt-2'>
                                        <Icons.CarSpeed />
                                        <span>{detailsData.att3} {isLang === "en" ? 'km per day' : ' كيلو في اليوم'}   </span>
                                    </li>
                                }


                                {
                                    detailsData?.lim_km_price &&
                                    <>
                                        <div className='mt-3 lim_km_price shadow-1'>
                                            <span> {isLang === "en" ? `For each kilometer, an additional ${detailsData?.lim_km_price} SAR .` : ` علي كل كيلومتر زياده ${detailsData?.lim_km_price} ريال `} </span>
                                        </div>
                                    </>
                                }

                            </ul>
                        </div>
                    </Col>
                    <Col xl={5} lg={5} md={12} sm={12} className='mt-8 slider_car_details' dir='ltr'>
                        <Carousel
                            value={detailsData?.banners}
                            itemTemplate={(banner) => (
                                <img
                                    src={banner.image}
                                    className="w-100 object-fit-contain"
                                    alt="banner"
                                />
                            )}
                            responsiveOptions={responsiveOptions}
                            circular
                        />
                    </Col>
                </Row>
            </Container>


            <div className={`mt-5  payment_bg relative ${detailsData?.banners?.length > 0 ? '' : 'banners_top_100'} `}>
                <Container>
                    <div className="app__payment_details   mb-40" dir={isLang === "en" ? 'ltr' : 'rtl'}>

                        <Row>

                            {
                                bookingValidStep1 === false ?
                                    <Col xl={8} lg={8} md={12} sm={12}>
                                        <form onSubmit={formikReservationsApi.handleSubmit}>
                                            <Row>
                                                <Col xl={12} lg={12} md={12} sm={12} className='mt-3'>
                                                    <div className={`${isLang === "en" ? '' : 'Messages_Ar'} w-100 flex justify-content-center`} >
                                                        <Messages ref={msgs} className='w-100' />
                                                    </div>
                                                </Col>
                                                <Col xl={6} lg={6} md={6} sm={12} className={isLang === "en" ? 'Calendar_paymentEn mt-3' : ' mt-3 Calendar_payment'} >
                                                    <Calendar
                                                        id="startDate"
                                                        name="startDate"
                                                        value={storeStartDate instanceof Date ? storeStartDate : formikReservationsApi.values.startDate}
                                                        placeholder={isLang === "en" ? ' Pickup Date' : '  تاريخ بدأ الإيجار '}
                                                        onChange={async (e) => {
                                                            await formikReservationsApi.setFieldValue('startDate', e.value);
                                                            await formikReservationsApi.setFieldValue("endDate", null);
                                                            await handleStartDateChange(e.value);
                                                            await changeStartDateAndTime()
                                                        }}
                                                        showIcon
                                                        className="w-full"
                                                        dir={isLang === "en" ? 'ltr' : 'rtl'}
                                                        icon="pi pi-calendar-plus"
                                                        minDate={new Date()}
                                                        required
                                                    />

                                                </Col>
                                                <Col xl={6} lg={6} md={6} sm={12} className={isLang === "en" ? 'mt-3 Calendar_paymentEn' : 'mt-3 Calendar_payment'} >
                                                    <Calendar
                                                        id="endDate"
                                                        name="endDate"
                                                        value={storeEndDate instanceof Date ? storeEndDate : formikReservationsApi.values.endDate}
                                                        placeholder={isLang === "en" ? 'Return Date' : '  تاريخ نهايه الإيجار '}
                                                        onChange={async (e) => {
                                                            await formikReservationsApi.setFieldValue('endDate', e.value);
                                                            await handleEndDateChange(e.value);
                                                            await changeEndDateAndTime()
                                                            if (formikReservationsApi.values.endTime) {
                                                                validateTime(formikReservationsApi.values.endTime, 'end');
                                                            }

                                                        }}
                                                        showIcon
                                                        className="w-full"
                                                        icon="pi pi-calendar-plus"
                                                        minDate={
                                                            formikReservationsApi.values.startDate
                                                                ? new Date(formikReservationsApi.values.startDate.getTime())
                                                                : new Date()
                                                        }
                                                        required
                                                    />
                                                    {formikReservationsApi.errors.endDate && (
                                                        <small className="text-red-300">{formikReservationsApi.errors.endDate}</small>
                                                    )}
                                                </Col>
                                                {
                                                    checkAfterStartDay == true ? <>
                                                        {
                                                            checkDay == true ?
                                                                <>
                                                                    <Col xl={6} lg={6} md={6} sm={12} className={isLang === "en" ? 'Calendar_paymentEn mt-3' : 'Calendar_payment mt-3'} dir='ltr'>
                                                                        <div className="flex flex-column gap-2">
                                                                            <div className={isLang === "en" ? ' ' : 'calendar_styleAr'}>
                                                                                {/*            <Calendar
                                                                name="startTime"
                                                                value={formikReservationsApi.values.startTime}
                                                                onChange={(e) => {
                                                                    formikReservationsApi.setFieldValue("startTime", e.value);

                                                                }}
                                                                placeholder={isLang === "en" ? 'Pickup Time' : 'وقت بدأ الإيجار'}
                                                                showIcon
                                                                showTime={true}
                                                                hourFormat="24"
                                                                className='w-full'
                                                                dir="ltr"
                                                                timeOnly
                                                                showPeriod={true} // تمكين تغيير AM و PM
                                                                iconPos={isLang === "en" ? 'right' : 'left'}
                                                                icon="pi pi-clock"
                                                                required
                                                                minDate={new Date(storeStartDate instanceof Date ? storeStartDate : formikReservationsApi.values.startDate)} // تحديد الوقت الحالي كحد أدنى
                                                            /> */}
                                                                                <Calendar
                                                                                    name="startTime"
                                                                                    value={formikReservationsApi.values.startTime}
                                                                                    onChange={(e) => {
                                                                                        formikReservationsApi.setFieldValue("startTime", e.value);
                                                                                        validateTime(e.value, 'start');

                                                                                    }}
                                                                                    placeholder={isLang === "en" ? 'Pickup Time' : 'وقت بدأ الإيجار'}
                                                                                    showIcon
                                                                                    showTime={true}
                                                                                    hourFormat="24"
                                                                                    className='w-full'
                                                                                    dir="ltr"
                                                                                    timeOnly
                                                                                    showPeriod={true} // Enable changing AM and PM
                                                                                    iconPos={isLang === "en" ? 'right' : 'left'}
                                                                                    icon="pi pi-clock"
                                                                                    required
                                                                                    minDate={new Date(storeStartDate instanceof Date ? storeStartDate : formikReservationsApi.values.startDate)} // Set current time as minimum
                                                                                />
                                                                                {errorTime ?
                                                                                    <div dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                                                                        {
                                                                                            DayOfWeekStart === 5 ?
                                                                                                <Message severity="error" text={"يُرجى تحديد الوقت بين 4:30 مساءً - 10:00 مساءً"} /> :
                                                                                                <Message severity="error" text={"يُرجى تحديد الوقت بين 8:30 صباحاً - 10:30 مساءً"} />

                                                                                        }
                                                                                    </div> : null
                                                                                }
                                                                                {formikReservationsApi.errors.startTime && formikReservationsApi.touched.startTime ?
                                                                                    <div div dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                                                                        <Message severity="error" text={formikReservationsApi.errors.startTime} />
                                                                                    </div> : null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xl={6} lg={6} md={6} sm={12} className={isLang === "en" ? 'Calendar_paymentEn mt-3' : 'Calendar_payment mt-3'} dir='ltr'>
                                                                        <div className="flex flex-column time_res gap-2">

                                                                            <div className={isLang === "en" ? ' ' : 'calendar_styleAr'}>
                                                                                <Calendar
                                                                                    name="endTime"
                                                                                    value={formikReservationsApi.values.endTime}
                                                                                    onChange={(e) => {
                                                                                        formikReservationsApi.setFieldValue("endTime", e.value);
                                                                                        validateTime(e.value, 'end');
                                                                                    }}
                                                                                    placeholder={isLang === "en" ? 'Pickup Time' : 'وقت نهاية الإيجار'}
                                                                                    showIcon
                                                                                    showTime={true}
                                                                                    hourFormat="24"
                                                                                    className='w-full'
                                                                                    dir="ltr"
                                                                                    timeOnly
                                                                                    showPeriod={true} // تمكين تغيير AM و PM
                                                                                    iconPos={isLang === "en" ? 'right' : 'left'}
                                                                                    icon="pi pi-clock"
                                                                                    required
                                                                                    minDate={new Date(storeEndDate instanceof Date ? storeEndDate : formikReservationsApi.values.endDate)} // تحديد الوقت الحالي كحد أدنى
                                                                                />
                                                                                {formikReservationsApi.errors.endTime && formikReservationsApi.touched.endTime ?
                                                                                    <div div dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                                                                        <Message severity="error" text={formikReservationsApi.errors.endTime} />
                                                                                    </div> : null
                                                                                }
                                                                                {errorEndTime ?
                                                                                    <div dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                                                                        {
                                                                                            DayOfWeekEnd === 5 ?
                                                                                                <Message severity="error" text={"يُرجى تحديد الوقت بين 4:30 مساءً - 10:00 مساءً"} /> :
                                                                                                <Message severity="error" text={"يُرجى تحديد الوقت بين 8:30 صباحاً - 10:30 مساءً"} />

                                                                                        }
                                                                                    </div> : null
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </Col>

                                                                    <Col xl={6} lg={6} md={6} sm={12} className='mt-3'>
                                                                        <Dropdown
                                                                            id="type"
                                                                            name="type"
                                                                            value={formikReservationsApi.values.type}
                                                                            optionLabel="name"
                                                                            onChange={(e) => {
                                                                                setDelivery(e.value);
                                                                                formikReservationsApi.setFieldValue("type", e.value)
                                                                                if (e.value == 1) {
                                                                                    formikReservationsApi.setFieldValue("payType", 1)
                                                                                } else {
                                                                                    formikReservationsApi.setFieldValue("payType", 0)
                                                                                }
                                                                            }}
                                                                            onBlur={formikReservationsApi.handleBlur}
                                                                            options={delivery}
                                                                            placeholder={isLang === "en" ? ' How to receive the car  ' : ' مكان استلام السيارة  '}
                                                                            className="w-full  "

                                                                        />
                                                                        {formikReservationsApi.touched.type && formikReservationsApi.errors.type && (
                                                                            <small className="p-error">{formikReservationsApi.errors.type}</small>
                                                                        )}
                                                                    </Col>
                                                                    {
                                                                        deliveryType === 0 ?
                                                                            <>
                                                                                <Col xl={6} lg={6} md={6} sm={12} className='mt-3'>
                                                                                    <Dropdown
                                                                                        id="branchId"
                                                                                        name="branchId"
                                                                                        filter
                                                                                        value={storeBrancheFrom} // تحديد القيمة المختارة
                                                                                        options={brancheFrom}
                                                                                        optionLabel="name"
                                                                                        optionValue="id"
                                                                                        onChange={(e) => {
                                                                                            const selectedCity = brancheFrom.find(city => city.id == e.value);
                                                                                            formikReservationsApi.setFieldValue('cityFrom', selectedCity?.city_id)
                                                                                            formikReservationsApi.setFieldValue('branchId', e.value)
                                                                                            setStoreBrancheForm(e.value)
                                                                                        }}
                                                                                        className="w-100"
                                                                                        placeholder={isLang === "en" ? 'Pickup Branch ' : 'فرع الاستلام'}

                                                                                    />
                                                                                    {formikReservationsApi.touched.branchId && formikReservationsApi.errors.branchId && (
                                                                                        <small className="p-error">{formikReservationsApi.errors.branchId}</small>
                                                                                    )}
                                                                                </Col>

                                                                                <Col xl={6} lg={6} md={6} sm={12} className='mt-3'>
                                                                                    <Dropdown
                                                                                        filter
                                                                                        id="deliveringId"
                                                                                        name="deliveringId"
                                                                                        value={storeBrancheTo} // تحديد القيمة المختارة
                                                                                        options={brancheTo}
                                                                                        optionLabel="name"
                                                                                        onChange={(e) => {
                                                                                            const selectedCity = brancheTo.find(city => city.id == e.value);
                                                                                            formikReservationsApi.setFieldValue('cityTo', selectedCity?.city_id)
                                                                                            formikReservationsApi.setFieldValue('deliveringId', e.value)
                                                                                            setStoreBrancheTo(e.value)
                                                                                        }}
                                                                                        optionValue="id"
                                                                                        onBlur={formikReservationsApi.handleBlur}
                                                                                        className="w-full  "
                                                                                        dir={isLang === "en" ? 'ltr' : 'rtl'}
                                                                                        placeholder={isLang === "en" ? 'Return Branch ' : '     فرع التسليم  '}
                                                                                    />
                                                                                    {formikReservationsApi.touched.deliveringId && formikReservationsApi.errors.deliveringId && (
                                                                                        <small className="p-error">{formikReservationsApi.errors.deliveringId}</small>
                                                                                    )}
                                                                                </Col>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <Col xl={6} lg={6} md={6} sm={12} className='mt-3 dropdown_itemsAr'>
                                                                                    <Dropdown
                                                                                        filter
                                                                                        id="cityId"
                                                                                        name="cityId"
                                                                                        value={formikReservationsApi.values.cityId}
                                                                                        options={fetchCities.map(city => ({
                                                                                            name: isLang === 'en' ? city.name : city.arabic_name,
                                                                                            value: city.id,
                                                                                        }))}
                                                                                        optionLabel="name"
                                                                                        onChange={(e) => formikReservationsApi.setFieldValue("cityId", e.value)}
                                                                                        onBlur={formikReservationsApi.handleBlur}
                                                                                        className="w-full    "
                                                                                        dir={isLang === "en" ? 'ltr' : 'rtl'}
                                                                                        placeholder={isLang === "en" ? ' City' : 'المدينة'}

                                                                                    />
                                                                                    {formikReservationsApi.touched.cityId && formikReservationsApi.errors.cityId && (
                                                                                        <small className="p-error">{formikReservationsApi.errors.cityId}</small>
                                                                                    )}
                                                                                </Col>
                                                                                <Col xl={12} lg={12} md={6} sm={12} className='mt-3'>
                                                                                    <InputTextarea
                                                                                        id="address"
                                                                                        name="address"
                                                                                        type="text"
                                                                                        onBlur={formikReservationsApi.handleBlur}
                                                                                        onChange={formikReservationsApi.handleChange}
                                                                                        rows={4} cols={30}
                                                                                        className="w-full  "
                                                                                        aria-describedby="username-help"
                                                                                        placeholder={isLang === "en" ? 'Enter your address...' : 'أدخل عنوانك...'}

                                                                                    />
                                                                                    {formikReservationsApi.touched.address && formikReservationsApi.errors.address && (
                                                                                        <small className="p-error">{formikReservationsApi.errors.address}</small>
                                                                                    )}
                                                                                    {
                                                                                        deliveryType === 1 &&
                                                                                        <div className='map_sm_screen'>
                                                                                            <LocationGoogleMap
                                                                                                height="300px"
                                                                                            />
                                                                                        </div>
                                                                                    }
                                                                                </Col>
                                                                            </>
                                                                    }
                                                                    <Col xl={6} lg={6} md={6} sm={12} className='mt-3'>
                                                                        <Dropdown
                                                                            id="payType"
                                                                            name="payType"
                                                                            value={formikReservationsApi.values.payType}
                                                                            optionLabel="name"
                                                                            onChange={(e) => formikReservationsApi.setFieldValue("payType", e.value)}
                                                                            onBlur={formikReservationsApi.handleBlur}
                                                                            options={payType}
                                                                            placeholder={isLang === "en" ? 'Payment Method' : 'طريقة الدفع '}
                                                                            className="w-full  "
                                                                            disabled={formikReservationsApi.values.type == 1 ? true : false}
                                                                        />
                                                                        {formikReservationsApi.touched.payType && formikReservationsApi.errors.payType && (
                                                                            <small className="p-error">{formikReservationsApi.errors.payType}</small>
                                                                        )}
                                                                    </Col>
                                                                    <Col xl={6} lg={6} md={6} sm={12} className='mt-3'>
                                                                        <InputText
                                                                            className={`w-full   ${promoCheck ? 'p-invalid' : 'p-valid'}`}
                                                                            aria-describedby="username-help"
                                                                            placeholder={isLang === "en" ? 'Enter Promo Code' : '   ادخل كود الخصم '}
                                                                            id="promo"
                                                                            name="promo"
                                                                            type="text"
                                                                            onBlur={(e) => {
                                                                                formikReservationsApi.handleBlur(e);
                                                                                promoCodeCheckApi(e.target.value); // Trigger promo code check
                                                                            }}
                                                                            onChange={formikReservationsApi.handleChange}
                                                                        />

                                                                        {
                                                                            handelpromoCheck &&
                                                                            <>
                                                                                {promoCheck ?
                                                                                    <Message severity="error" text={`${promoValue} برومو كود غير فعال`} /> :
                                                                                    <Message severity="success" text={` برومو كود فعال   معاك خصم ${promoType === 0 ? `${promoValue + '%'}` : `${promoValue + '  ريال '}`} `} />}
                                                                            </>
                                                                        }
                                                                    </Col>
                                                                    <Col xl={6} lg={6} md={6} sm={12} className='mt-3'></Col>
                                                                </> :
                                                                <div className='mt-3 mb-8 lim_km_price  error_mess   flex justify-content-center align-items-center  shadow-3'>
                                                                    <span className='text-center  '> {isLang === "en" ? `We would like to inform you that the minimum duration for booking a car is ${Days} days.` : `نود إعلامكم بأن الحد الادني لحجز السيارة هو ${Days} أيام.   `} </span>
                                                                </div>
                                                        }

                                                    </> :
                                                        <div className='mt-3 mb-8 lim_km_price  error_mess   flex justify-content-center align-items-center  shadow-3'>
                                                            <span>{isLang === "en" ? `The start date of the rental should not exceed ${checkAfterStartDayValue} days from today.` : `يجب ألا يتجاوز تاريخ بدء الإيجار ${checkAfterStartDayValue} أيام من اليوم.`}</span>
                                                        </div>
                                                }

                                                {/* <Col xl={12} lg={12} md={12} sm={12} className='mt-5'>
                                                    <div className="flex flex-row gap-5">
                                                        <h6>{isLang === "en" ? 'The tenant is the same as the delegate?' : 'المستأجر هو نفسه المفوض؟'}</h6>
                                                        <div className="flex align-items-center gap-2">
                                                            <RadioButton
                                                                inputId="driverYes"
                                                                name="driver"
                                                                value={1}
                                                                onChange={formikReservationsApi.handleChange}
                                                                onBlur={formikReservationsApi.handleBlur}
                                                                checked={formikReservationsApi.values.driver === 1}
                                                            />
                                                            <label htmlFor="driverYes" className="ml-2">{isLang === "en" ? 'Yes' : 'نعم'}</label>
                                                        </div>

                                                        <div className="flex align-items-center gap-2">
                                                            <RadioButton
                                                                inputId="driverNo"
                                                                name="driver"
                                                                value={0}
                                                                onChange={formikReservationsApi.handleChange}
                                                                onBlur={formikReservationsApi.handleBlur}
                                                                checked={formikReservationsApi.values.driver === 0}
                                                            />
                                                            <label htmlFor="driverNo" className="ml-2">{isLang === "en" ? 'No' : 'لا'}</label>
                                                        </div>
                                                    </div> 
                                                    {formikReservationsApi.errors.driver && formikReservationsApi.touched.driver && (
                                                        <small className="p-error">{formikReservationsApi.errors.driver}</small>
                                                    )}
                                                </Col>

                                                <Col xl={12} lg={12} md={12} sm={12} className='mt-5'>
                                                    <div className="flex flex-row gap-5">
                                                        <h6>{isLang === "en" ? 'Is there an extra driver?' : 'هل يوجد سائق اضافي ؟'}</h6>
                                                        <div className="flex align-items-center gap-2">
                                                            <RadioButton
                                                                inputId="authorizedYes"
                                                                name="authorized"
                                                                value={1}
                                                                onChange={formikReservationsApi.handleChange}
                                                                onBlur={formikReservationsApi.handleBlur}
                                                                checked={formikReservationsApi.values.authorized === 1}
                                                            />
                                                            <label htmlFor="authorizedYes" className="ml-2">{isLang === "en" ? 'Yes' : 'نعم'}</label>
                                                        </div>

                                                        <div className="flex align-items-center gap-2">
                                                            <RadioButton
                                                                inputId="authorizedNo"
                                                                name="authorized"
                                                                value={0}
                                                                onChange={formikReservationsApi.handleChange}
                                                                onBlur={formikReservationsApi.handleBlur}
                                                                checked={formikReservationsApi.values.authorized === 0}
                                                            />
                                                            <label htmlFor="authorizedNo" className="ml-2">{isLang === "en" ? 'No' : 'لا'}</label>
                                                        </div>
                                                    </div>

                                                    {formikReservationsApi.errors.authorized && formikReservationsApi.touched.authorized && (
                                                        <small className="p-error">{formikReservationsApi.errors.authorized}</small>
                                                    )}
                                                </Col> */}

                                                {
                                                    checkDay == true ?
                                                        <Row>
                                                            <Col xl={4} lg={3} md={3} sm={12} className=' payment_footer_btn'>
                                                                {/*   {
                                                            !reservationCheck && */}
                                                                <div className="cal_price">
                                                                    <Component.Title title={isLang === "en" ? 'Total Amount ' : '  إجمالى المبلغ '} />
                                                                    <div className="price_summary">
                                                                        {/* <h4> {detailsData.offer === 1 ? detailsData.offer_price * CalcDays : detailsData.price * CalcDays} {isLang === "en" ? 'SAR' : 'ريال'}  </h4> */}
                                                                        <div className="price_summary">
                                                                            <h4>
                                                                                {isNaN(detailsData.offer === 1 ? detailsData.offer_price * CalcDays : detailsData.price * CalcDays) ? 0 :
                                                                                    (detailsData.offer === 1 ? detailsData.offer_price * CalcDays : detailsData.price * CalcDays)
                                                                                } {isLang === "en" ? 'SAR' : 'ريال'}
                                                                            </h4>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                {/* } */}
                                                            </Col>

                                                            <Col xl={8} lg={9} md={9} sm={12} className='payment_footer_btn payment_footer_btn2  gap-5 flex flex-row align-items-end'>
                                                                <Row className='w-100 payment_footer_btn_gap'>
                                                                    <Col xl={5} lg={5} md={6} sm={12}  >
                                                                        <Link to={`${localStorage.getItem('path')}`}>
                                                                            <div className="car_back   w-100" >
                                                                                <Button className='w-12' icon={`pi ${isLang === "en" ? 'pi-caret-left' : 'pi-caret-right'}`} dir={isLang === "en" ? 'ltr' : 'rtl'}
                                                                                    label={isLang === "en" ? 'Back to cars ' : '    الرجوع للسيارات'} severity="secondary" />
                                                                            </div>
                                                                        </Link>
                                                                    </Col>
                                                                    <Col xl={7} lg={7} md={6} sm={12} >
                                                                        <div className="btn_pay w-100">
                                                                            <Button className='w-12'/*  disabled={!formikReservationsApi.isValid} */ icon={`pi ${isLang === "en" ? 'pi-caret-right' : 'pi-caret-left'}`} dir='ltr' label={isLang === "en" ? 'Complete payment ' : 'إكمال الدفع'} severity="secondary" />
                                                                        </div>
                                                                    </Col>

                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        : ''
                                                }
                                            </Row>
                                        </form>
                                    </Col>
                                    :
                                    <Col xl={8} lg={8} md={12} sm={12}>
                                        <Component.ReservationsBefore
                                            id={id}
                                            isLang={isLang}
                                        />
                                    </Col>
                            }
                            {
                                deliveryType === 1 &&
                                <Col xl={4} lg={4} md={12} sm={12} className=' mt-3' >
                                    <div className='map_lg_screen'>
                                        {
                                            checkAfterStartDay && checkDay && (
                                                <LocationGoogleMap height="500px" />
                                            )
                                        }
                                    </div>
                                </Col>
                            }

                        </Row>


                    </div>
                </Container >

                <Component.PaymentModal
                    promoCheck={promoCheck1}
                    promoType={promoType}
                    promoValue={promoValue}
                    getReservationsData={getReservationsData}
                    cat_id={detailsData?.cat_id}
                    nationalId={fetchPersonalData?.nationalId?.charAt(0)}
                    price={detailsData.offer === 1 ? detailsData.offer_price_before_tax : detailsData.price_before_tax}
                    price_after_tax={detailsData.offer === 1 ? detailsData.offer_price : detailsData.price}
                    totalPrice={detailsData.offer === 1 ? detailsData.offer_price_before_tax * CalcDays : detailsData.price_before_tax * CalcDays}

                    priceTax={getReservationsData?.tax}
                    carImage={detailsData.image}
                    carName={detailsData.name}
                    // DeliveryExpenses={150}
                    // DeliveryExpenses={detailsData.delivery_price}
                    DeliveryExpenses={deliveryFeesData}

                    show={modalShow}
                    setModalShow={setModalShow}
                    onHide={() => setModalShow(false)}
                    selectedStartDate={selectedStartDate}
                    selectedEndDate={selectedEndDate}
                    CalcDays={CalcDays}
                    promoCodeValue={promoCodeValue}
                    deliveryType={deliveryType}
                    overKM={overKM}
                />

                <div className='absolute Bg_payment'>
                    <Icons.Bg_payment />
                </div>


            </div >
            <Modal
                show={show}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleClose}
                animation={true}
            >
                <Modal.Body>
                    <div className='terms_popup mt pb-7 px-2'>
                        <Container dir={isLang == "en" ? 'ltl' : 'rtl'}>
                            <header className='flex justify-content-center'>
                                <img src={img.Logo3} alt="logo alghazal" width={150} />
                            </header>
                            <p >
                                {isLang == "en" ? "Working hours for booking: From Saturday to Thursday from 8:30 AM to 10:30 PM, and Friday from 4:30 PM to 10:00 PM." : "مواعيد الدوام للحجز: من السبت إلى الخميس من الساعة 8:30 صباحًا حتى 10:30 مساءً، والجمعة من 4:30 مساءً حتى 10:00 مساءً."}
                            </p>
                        </Container>
                    </div>
                </Modal.Body>

            </Modal>

            <Modal
                show={showMaintenance}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleCloseMaintenance}
                animation={true}
            >
                <Modal.Body>
                    <div className='terms_popup mt pb-4 px-2'>
                        <Container dir={isLang == "en" ? 'ltl' : 'rtl'} className='  flex-column'>
                            <header className='flex justify-content-center'>
                                <img src={img.Logo3} alt="logo alghazal" width={150} />
                            </header>
                            <label htmlFor="old" className="font-bold text-center block mb-2">  {isLang === "en" ? 'Please complete and confirm the reservation steps from the branch' : 'برجاء استكمال وتأكيد خطوات الحجز من الفرع'}</label>



                            <div className=" mt-3  btn_notes_choose  flex justify-content-center align-items-center gap-4 w-100">
                                <Link to={'/branches'}>
                                    <Button
                                        severity="warning"
                                        dir='ltr'
                                        size='small'
                                        className='w-8rem'
                                        label={isLang === "en" ? 'Branches' : 'الفروع'}

                                    />
                                </Link>
                                <div className="outlined">
                                    <Button
                                        severity="warning"
                                        dir='ltr'
                                        size='small'
                                        outlined
                                        className='w-8rem'

                                        label={isLang === "en" ? 'Cancel' : 'إلغــاء'}
                                        onClick={handleCloseMaintenance}
                                    />

                                </div>
                            </div>
                        </Container>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default PaymentDetails