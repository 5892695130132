import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Component from '../../../constants/Component';
import { LocalizationContext } from '../../../context/LangChange';
import { Data } from '../Data';
import '../style.scss'
import { Helmet } from 'react-helmet-async';
import { SharedData } from '../../../context/CallApi';
const Policy = () => {
    let { isLang } = useContext(LocalizationContext);

    const [data, setData] = useState([]);
    useEffect(() => {
        Data.fetchPrivacyPolicy().then((data) => setData(data));
    }, []);
    useEffect(() => {
        window.scrollBy(0, -window.pageYOffset);
    }, [])
    let { seo, fetchSEO } = useContext(SharedData);
    useEffect(() => {
        fetchSEO('PRIVACY')
    }, [])
    return (
        <div className="app__wallet mt-70 py-7">
            <Helmet >
            <title>{isLang === "en" ? 'Privacy Policy | Alghazal ' : '  سياسة الخصوصية | الغزال لتأجير السيارات'}</title>

                <meta name="title" content={seo?.title} />
                <meta name="description" content={seo?.desc} />
                <meta name="keywords" content={seo?.keywords} />

                <meta property="og:title" content={seo.title} />
                <meta property="og:description" content={seo?.desc} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className='privacy_policy mt pb-7 px-2'>
                <Container>
                    <header className=' '>
                        <Component.Title headerSeo="h1" title={isLang === "en" ? '  Privacy policy' : 'سياسة الخصوصية'} />
                    </header>
                    <Row dir={isLang === "en" ? 'ltr' : 'rtl'}>
                        <Col xl={6} lg={6} md={12} sm={12} >
                            {
                                isLang === "en" ?
                                    <section className='content mt-4'>
                                        <h4>Information collection and use</h4>
                                        <p>
                                            We take our responsibilities regarding the protection of personal information very seriously. This policy explains how we use personal information that we may obtain from you. By using our website www. alghazal.sa, (henceforth referred to as Alghazal) which also comprises the mobile site, smartphone app platforms such as iOS, Android and Windows (altogether referred to as “site”), you consent to this use. This privacy policy does not apply to the websites of our business partners, corporate affiliates or to any other third parties, even if their websites are linked to the site. We recommend you review the respective privacy statements of the other parties with whom you interact.                                                      </p>
                                    </section> :
                                    <section className='content mt-4'>
                                        <h4>جمع المعلومات واستخدامها </h4>
                                        <p>
                                            تقع على عاتقنا مهمة حماية معلوماتك الشخصية، ويتم توضيح هذه السياسة وكيفية استخدام معلوماتك الشخصية عبر موقع www. alghazal.sa والتطبيق المتوفر على الأجهزة الذكية أيفون، أندرويد، ويندوز (المشار إليها جميعاً في الموقع). علماً بأن سياسة الخصوصية هذه لا تنطبق على مواقع شركائنا في العمل، الشركات التابعة، أو أي أطراف أخرى، وحتى إن تم الإشارة إليهم على الموقع. لذا ننصحك بمراجعة سياسة الخصوصية العائدة للأطراف الأخرى التي تود التعامل معها. عند استخدامك للخدمات المتوفرة على الموقع أو تطبيقات الموبايل، سيتم طلب تقديم بعض المعلومات مثل اسمك، عناوين الاتصال، بطاقة الائتمان أو بطاقة الخصم. ويتم تخزين هذه المعلومات والاحتفاظ بها على أجهزة الكمبيوتر أو بصيغة آمنة ومشفرة.
                                        </p>
                                    </section>
                            }

                            {
                                data?.slice(0, 2).map((item, index) => (
                                    <>
                                        <section key={index} className='content mt-4'>
                                            <h4>   {isLang === "en" ? item.title : item.titleAr}  </h4>
                                            <p> {isLang === "en" ? item.desc : item.descAr} </p>
                                            <ul className='mt-5'>
                                                {
                                                    item?.list.map((item, index) => (
                                                        <li key={index} className='mt-2'> <strong>{isLang === "en" ? item?.title : item?.titleAr}</strong>{isLang === "en" ? item?.body : item?.bodyAr}</li>
                                                    ))
                                                }
                                            </ul>
                                            <p> {isLang === "ar" ? item.body : item?.bodyEn} </p>


                                        </section>

                                        <hr />
                                    </>

                                ))
                            }
                            <section className='content mt-4'>
                                <h4> {isLang === "en" ? 'How to Contact Us ' : 'كيف تتصل بنا'}</h4>
                                <p> {isLang === "en" ? 'If you have any questions or concerns about this Privacy Policy, please Contact Us:' : ' إذا كان لديك أي أسئلة أو مخاوف بشأن سياسة الخصوصية هذه، فيرجى الاتصال بنا:'}</p>
                                <ul className='mt-5'>
                                    <li className='mt-4'> <strong> {isLang === "en" ? 'Via email: ' : 'عبر البريد الإلكتروني: '} </strong> <a href="mailto:rent@alghazal.sa">  rent@alghazal.sa</a> </li>
                                    <li className='mt-3'> <strong>   {isLang === "en" ? 'By phone: ' : 'عبر رقم الجوال : '} </strong><a href="tel:+966125444141" dir='ltr'>+966125444141</a> </li>
                                </ul>
                            </section>

                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12}  >

                            {
                                data?.slice(3, data.length).map((item, index) => (
                                    <>
                                        <section key={index} className='content mt-4'>
                                            <h4>   {isLang === "en" ? item.title : item.titleAr}  </h4>
                                            <p> {isLang === "en" ? item.desc : item.descAr} </p>
                                            <ul className='mt-5'>
                                                {
                                                    item?.list.map((item, index) => (
                                                        <li key={index} className='mt-2'> <strong>{isLang === "en" ? item?.title : item?.titleAr}</strong>{isLang === "en" ? item?.body : item?.bodyAr}</li>
                                                    ))
                                                }
                                            </ul>
                                            <p> {isLang === "ar" ? item.body : item?.bodyEn} </p>

                                        </section>

                                        <hr />
                                    </>
                                ))
                            }



                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Policy