import { motion } from 'framer-motion';
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Message } from 'primereact/message';
import React, { useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Banner from '../../../components/Banner/Banner';
import Component from '../../../constants/Component';
import Icons from '../../../constants/Icons';
import { SharedData } from '../../../context/CallApi';
import { LocalizationContext } from '../../../context/LangChange';
import useConnectApi from '../../services/ContactApi';
import img from './../../../constants/Img';
import './connect.scss';
import { Helmet } from 'react-helmet-async';

const Connect = () => {
  let { isLang } = useContext(LocalizationContext);

  let { fetchCities, contactsData } = useContext(SharedData);

  const { formik } = useConnectApi(); // Use the formik configuration
  useEffect(() => {
    window.scrollBy(0, -window.pageYOffset);
  }, [])
  let { seo, fetchSEO } = useContext(SharedData);
  useEffect(() => {
    window.scrollBy(0, -window.pageYOffset);
    fetchSEO('CONTACTS')
  }, [])
  return (
    <>
      <Helmet >
        <title>{isLang === "en" ? 'Contact Us | Alghazal ' : '   تواصل معنا | الغزال لتأجير السيارات'}</title>

        <meta name="title" content={seo?.title} />
        <meta name="description" content={seo?.desc} />
        <meta name="keywords" content={seo?.keywords} />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo?.desc} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Banner
        img={img.BgConnect}
        title={isLang === "en" ? ' Contact Us' : 'تواصل معنا '}
        dec={isLang === "en" ? 'We are always happy to help! Our Customer Service is here to answer your inquiries.' : ' نحن دائما سعداء للمساعدة! خدمة العملاء لدينا هنا للرد على استفساراتك. '}
      />
      <div className="app__contact   pb-45 mt-30 mb-50 " dir={isLang === "en" ? 'ltr' : 'rtl'}>
        <Container>
          <Component.Title title={isLang === "en" ? '   Stay In Touch' : '  ابقى على تواصل '} />
          <Row className='mt-3'>
            <Col xl={6} lg={6} md={6} sm={12} className='mt-3 '>
              <form onSubmit={formik.handleSubmit} className='w-100'>
                <div className='lg:w-9  md:w-full'>
                  <Row>
                    <Col xl={6} lg={6} md={12} sm={12} className='mt-3'>

                      <InputText
                        id="mobile"
                        name="mobile"
                        type="text"
                        value={formik.values.mobile}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        aria-describedby="username-help"
                        placeholder={isLang === "en" ? 'Phone Number*' : 'رقم الجوال*'}
                        className='bg-light w-100' />

                      {formik.errors.mobile && formik.touched.mobile ?
                        <Message severity="error" text={formik.errors.mobile} /> : null
                      }

                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} className='mt-3'>
                      <InputText
                        id="name"
                        name="name"
                        type="text"
                        value={formik.values.name}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        aria-describedby="username-help"
                        placeholder={isLang === "en" ? 'Full Name*' : ' الاسم الكامل * '}
                        className='bg-light w-100' />

                      {formik.errors.name && formik.touched.name ?
                        <Message severity="error" text={formik.errors.name} /> : null
                      }

                    </Col>

                    <Col xl={12} lg={12} md={12} sm={12} className='mt-3'>
                      <InputText
                        id="email"
                        name="email"
                        type="email"
                        value={formik.values.email}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        aria-describedby="username-help"
                        placeholder={isLang === "en" ? 'Email*' : '    بريد الإلكتروني * '}
                        className='bg-light w-100' />

                      {formik.errors.email && formik.touched.email ?
                        <Message severity="error" text={formik.errors.email} /> : null
                      }

                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12} className='mt-3'>
                      <InputTextarea
                        id="message"
                        name="message"
                        type="text"
                        value={formik.values.message}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder={isLang === "en" ? 'Message Details **' : ' تفاصيل الرساله   *  '}
                        rows={8} cols={30}
                        className='bg-light w-100' />

                      {formik.errors.message && formik.touched.message ?
                        <Message severity="error" text={formik.errors.message} /> : null
                      }

                    </Col>
                  </Row>
                  <div className="btn_submit btn_2">
                    <Button label={isLang === "en" ? ' Send' : ' إرســــــل  '} severity='secondary' rounded className='w-4 mt-3' />
                  </div>
                </div>
              </form>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} className='mt-5'>
              <div className="app__content-social">
                {
                  contactsData?.phone &&
                  <div className="phone">
                    <Icons.CallBold />
                    <div className="content">
                      <h3> {isLang === "en" ? ' Mobile Number' : ' رقم الجــوال  '} </h3>
                      <span dir='ltr'> <a href={`tel:${contactsData?.phone} `} className='text-dark'>{contactsData?.phone} </a>      </span>
                    </div>
                  </div>
                }
                {contactsData?.email &&
                  <div className="email">
                    <Icons.Email2 />
                    <div className="content">
                      <h3> {isLang === "en" ? 'E-mail' : ' البريد الالكترونى'} </h3>
                      <span> <a href={`mailto:${contactsData?.email} `} className='text-dark'>{contactsData?.email} </a>   </span>
                    </div>
                  </div>
                }
                <div className="branch">
                  <Icons.Location />
                  <div className="content">
                    <h3> {isLang === "en" ? 'Company Branches' : 'فروع الشركة '} </h3>
                    <h3>  </h3>
                    <span className='branches'>
                      {
                        fetchCities.map((item, index) => (
                          <span key={index}>{isLang === "en" ? item.name : item.arabic_name} {' ,  '}</span>
                        ))
                      } <Link to={'/branches'}> <Icons.Youtube />  </Link>
                    </span>
                  </div>
                </div>
                <div className="social">
                  {contactsData?.twitter &&
                    <motion.a
                      href={contactsData?.twitter}
                      target='_blank' rel="noreferrer"
                      whileHover={{ scale: 1.2 }}
                      transition={{ duration: 0.3 }}
                    >
                      <Icons.Twitter color={'#fff'} />
                    </motion.a>
                  }
                  {contactsData?.facebook &&
                    <motion.a
                      href={contactsData?.facebook}
                      target='_blank' rel="noreferrer"
                      whileHover={{ scale: 1.2 }}
                      transition={{ duration: 0.3 }}
                    >
                      <Icons.Facebook color={'#fff'} className='facebook' />
                    </motion.a>
                  }
                  {contactsData?.instagram &&
                    <motion.a
                      href={contactsData?.instagram}
                      target='_blank' rel="noreferrer"
                      whileHover={{ scale: 1.2 }}
                      transition={{ duration: 0.3 }}
                    >
                      <Icons.Instagram color={'#fff'} />
                    </motion.a>
                  }
                  {/* {contactsData?.linkedin &&
                    <motion.a
                      href={contactsData?.linkedin}
                      target='_blank' rel="noreferrer"
                      whileHover={{ scale: 1.2 }}
                      transition={{ duration: 0.3 }}
                    >
                      <Icons.Linkedin color={'#fff'} />
                    </motion.a>
                  } */}
                  {contactsData?.whatsapp &&
                    <motion.a
                      href={`https://wa.me/${contactsData?.whatsapp}`}
                      target='_blank' rel="noreferrer"
                      whileHover={{ scale: 1.2 }}
                      transition={{ duration: 0.3 }}
                    >
                      <Icons.Whatsapp />
                    </motion.a>
                  }
                </div>

              </div>
            </Col>

          </Row>
        </Container>
      </div>


    </>
  )
}

export default Connect
