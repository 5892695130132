import axios from 'axios';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useContext, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import Component from '../../../../constants/Component';
import Icons from '../../../../constants/Icons';
import { LocalizationContext } from '../../../../context/LangChange';
import './style.scss';

const Services = () => {


    let { isLang } = useContext(LocalizationContext);
    const [subscription, setSubscription] = useState(false)
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: async (values, { resetForm }) => {
            const url = `${process.env.REACT_APP_API_URL}/subscription?email=${values.email}`;
            let { data } = await axios.post(url)

            if (data.status === true) {
                resetForm()
                setSubscription(true)
                if (data?.msg === 'DONE') {
                    toast.success(isLang === "ar" ? 'لقد تم الاشتراك  ' : 'You have been actively subscribed')
                }

            } else {
                if (data?.msg === 'ALREADY_EXIST') {
                    toast.error(isLang === "ar" ? ' بريد الإلكتروني موجود مسبقا' : 'Email already exists')
                }

            }
        }
    });
    return (
        <>

            <div className="app__service-home pt-45 pb-45" dir={isLang === "en" ? 'ltr' : 'rtl'}>
                <Container>
                    <Component.Title title={isLang === "en" ? 'Our Services' : 'خدماتنا'} />
                    <Row className='mt-5' >
                        <Col xl={4} lg={4} className='flex mt-3 justify-content-center flex-column align-items-center' md={12} sm={12}>
                            <Icons.ServicesCars2 />
                            {/* <div className="content">
                                <h2>{isLang === "en" ? 'Money Back Guaranteed' : 'ضمان استعادة الاموال'}</h2>
                                <p>{isLang === "en" ? 'We return the money within 30 days' : 'نعيد الأموال في غضون 30 يومًا'}</p>
                            </div> */}

                            <div className="content">
                                <h2>{isLang === "en" ? 'Latest models' : 'أحدث الموديلات'}</h2>
                                <p>{isLang === "en" ? 'We provide the latest brands and models of cars ' : 'نوفر أحدث ماركات وموديلات السيارات'}</p>
                            </div>
                        </Col>

                        <Col xl={4} lg={4} md={12} sm={12} className='flex mt-3 justify-content-center flex-column align-items-center'>
                            <Icons.ServicesCars />

                            <div className="content">
                                <h2>{isLang === "en" ? '24/7 Customer Service' : '24/7 خدمة العملاء'}</h2>
                                <p>{isLang === "en" ? 'Friendly 24/7 customer support' : 'دعم عملاء ودود على مدار الساعة طوال أيام الأسبوع'}</p>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={12} sm={12} className='flex  mt-3 justify-content-center flex-column align-items-center'>
                            <Icons.ServicesCars3 />

                            <div className="content">
                                <h2>{isLang === "en" ? 'Fast Delivery For Cars' : 'توصيل سريع للسيارات'}</h2>
                                <p>{isLang === "en" ? 'Delivery of cars to the location you specify' : 'توصيل للسيارات فى المكان الذى تحدده'}</p>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>

            <div className='app__service2-home'>
                <h1>{isLang === "en" ? 'Join The Al-Ghazal Community For A Monthly Promotion' : 'انضم إلى مجتمع الغزال للحصول على عرض ترويجي شهري'}</h1>
                <p>{isLang === "en" ? 'Enter your email below and you will receive all the latest news' : 'اكتب بريدك الإلكتروني في الأسفل وسيصلك كل ما هو جديد'}</p>
                {
                    subscription ?
                        <h2>{isLang === "en" ? "You have been actively subscribed" : 'لقد تم اشتراكك  '}</h2> :
                        <form onSubmit={formik.handleSubmit} className='w-100'>
                            <div className="groups_btn-input  m-auto" >
                                <InputText
                                    id="email"
                                    name="email"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    type='email' placeholder={isLang === "en" ? 'Email' : 'البريد الالكترونى'} dir={isLang === "en" ? 'ltr' : 'rtl'} className='w-12' />
                                <Button label={isLang === "en" ? 'Send' : 'إرسال'} type='submit' />
                            </div>
                        </form>
                }
            </div>
        </>
    )
}

export default Services
