import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Paginator } from 'primereact/paginator'
import { Skeleton } from 'primereact/skeleton'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import Banner from '../../../components/Banner/Banner'
import Component from '../../../constants/Component'
import Icons from '../../../constants/Icons'
import img from '../../../constants/Img'
import { FiltersApis } from '../../../context/CarFiltersApis'
import { LocalizationContext } from '../../../context/LangChange'
import axios from 'axios'



const LimousineCars = () => {

    let { isLang } = useContext(LocalizationContext);
    let { first } = useContext(FiltersApis);
    const location = useLocation();
    const [LoadingCars, setLoadingCars] = useState(false);

    const [carData, setCarData] = useState([]);

    useEffect(() => {
        const currentPath = location.pathname;
        localStorage.setItem('path', currentPath)
    }, [])
    const fetchCarsAll = async () => {
        const url = `${process.env.REACT_APP_API_URL}/limousine`;
        let { data } = await axios.get(url)
        setCarData(data);
    }
    useEffect(() => {
        fetchCarsAll()
        // window.scrollBy(0, -window.pageYOffset);
    }, [])

    return (
        <div className=' '>
            <Banner
                img={img.BgCars}
                title={isLang === "en" ? 'Set off on an unforgettable adventure!' : 'انطلق في مغامرة لا تُنسى!'}
                dec={isLang === "en" ? 'We guarantee an easy and smooth experience through a simple booking process and reasonable prices that suit your budget. ' : 'نضمن لك تجربة سهلة وسلسة من خلال عملية حجز بسيطة وأسعار معقولة تناسب ميزانيتك. احجز اليوم واستمتع برحلة لا تُنسى!                '}
            />
            <Component.FiltersCars />

            <div className='container_offers_ShownCars' >

                <div className="offers_ShownCars mt-40 mb-40" dir={isLang === "en" ? 'ltr' : 'rtl'} >

                    <Row style={{ width: '95%' }} className='m-auto'>

                        {
                            !LoadingCars ?
                                <>
                                    {
                                        carData?.map((item, index) => (
                                            <>
                                                <Col key={index} xl={4} className='relative  flex justify-content-center align-items-center mt-6 ' lg={4} md={6} sm={12}>
                                                    <Card
                                                        title={`${isLang === "en" ? item.brand : item.brand_arabic}` + '  ' + `${isLang === "en" ? item.sub_brand : item.sub_brand_arabic}`}
                                                        header={<img src={item.image}
                                                            className='w-100  IMA_CAR' alt={`${isLang === "en" ? item.brand : item.brand_arabic}` + '  ' + `${isLang === "en" ? item.sub_brand : item.sub_brand_arabic}`} loading='lazy' />}
                                                    >
                                                        <div className="slider_card" dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                                            <ul>
                                                                <li>
                                                                    <Icons.SliderCarSedan />
                                                                    <strong className='bold'>{item.year} {' '} {isLang === "en" ? item.type : item.type_arabic}  </strong>
                                                                </li>
                                                                <li>
                                                                    <Icons.SliderCarMoney />
                                                        
                                                                            <span>{item.lim_price} {isLang === "en" ? 'SR' : 'ريال'}</span>
                                                              

                                                                    <span className='oneDay mr-4' >  {isLang === "en" ? 'Per day/tax included' : 'لليوم / شامل الضريبة'}</span>

                                                                </li>
                                                                <li>
                                                                    <Icons.SliderCarPeople />
                                                                    <span>{item.pasengers} {isLang === "en" ? 'Pasengers' : 'راكب'}   </span>
                                                                </li>
                                                                {item.aircondition !== 0 && <li>
                                                                    <Icons.SliderCarSoil />
                                                                    <span>    {isLang === "en" ? 'Aircondition' : 'مكيفة'} </span>
                                                                </li>}

                                                                {item.aircondition === 0 && <li>
                                                                    <Icons.SliderCarSoil />
                                                                    <span>    {isLang === "en" ? 'Non-air conditioned' : 'غير مكيفة'} </span>
                                                                </li>}




                                                            </ul>
                                                            <div className="order   flex justify-content-end " >
                                                                <Link to={`/${isLang}/cars/limousine/details/${item.id}?car-name=${item.brand_arabic}_${item.sub_brand_arabic}&car-lim=1`}>
                                                                    <Button label={isLang === "en" ? 'Book now ' : '  احجزها الآن '} size='small' severity="warning" />
                                                                </Link>

                                                            </div>

                                                            {/*              <div className='mt-3 lim_km_price shadow-1'>
                                                                <span>    {isLang === "en" ? `The car covers 300 km, exceeding incurs a fine of ${item?.lim_km_price} SAR per 1 km.` :    `السيارة تقطع 300كم ،إن تجاوز يُغرم برسوم ${item?.lim_km_price} ريال لكل 1 كم`} </span>
                                                            </div> */}
                                                        </div>
                                                    </Card>
                                            
                                                </Col>
                                            </>
                                        ))
                                    }
                                </>
                                :
                                <>

                                    <Col xl={4} className='relative flex justify-content-center align-items-center mt-6 ' lg={4} md={6} sm={12}>
                                        <Skeleton size="20rem"></Skeleton>
                                    </Col>
                                    <Col xl={4} className='relative flex justify-content-center align-items-center mt-6 ' lg={4} md={6} sm={12}>
                                        <Skeleton size="20rem"></Skeleton>
                                    </Col>
                                    <Col xl={4} className='relative flex justify-content-center align-items-center mt-6 ' lg={4} md={6} sm={12}>
                                        <Skeleton size="20rem"></Skeleton>
                                    </Col>
                                </>
                        }

                    </Row>
                </div>
            </div>
        </div>
    )
}

export default LimousineCars
