import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Paginator } from 'primereact/paginator'
import { Skeleton } from 'primereact/skeleton'
import React, { useContext, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import Banner from '../../../components/Banner/Banner'
import Component from '../../../constants/Component'
import Icons from '../../../constants/Icons'
import img from '../../../constants/Img'
import { FiltersApis } from '../../../context/CarFiltersApis'
import { LocalizationContext } from '../../../context/LangChange'
import './style.scss'
import { Helmet } from 'react-helmet-async'
import { SharedData } from '../../../context/CallApi'

const ShownCars = () => {
  let { isLang } = useContext(LocalizationContext);
  let { carData, LoadingCars, page, rows, setRows, first, setFirst } = useContext(FiltersApis);


  const onPageChange = (event) => {
    const newPage = event.first;
    setFirst(newPage);
    setRows(event.rows);
  };
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem('path', currentPath)
  }, [])

  useEffect(() => {
    window.scrollBy(0, -window.pageYOffset);
  }, [])
  let { seo, fetchSEO } = useContext(SharedData);
  useEffect(() => {
    fetchSEO('CARS')

  }, [])
  return (
    <div>
      <Helmet >
        <title>{isLang === "en" ? 'Fleet | Alghazal' : 'أسطـول الغزال'}</title>
        <meta name="title" content={seo?.title} />
        <meta name="description" content={seo?.desc} />
        <meta name="keywords" content={seo?.keywords} />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo?.desc} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Banner
        img={img.BgCars}
        title={isLang === "en" ? 'Set off on an unforgettable adventure!' : 'انطلق في مغامرة لا تُنسى!'}
        dec={isLang === "en" ? 'We guarantee an easy and smooth experience through a simple booking process and reasonable prices that suit your budget. ' : 'نضمن لك تجربة سهلة وسلسة من خلال عملية حجز بسيطة وأسعار معقولة تناسب ميزانيتك. احجز اليوم واستمتع برحلة لا تُنسى!                '}
      />
      <Component.FiltersCars />
      <div className='container_offers_ShownCars' >

        <div className="offers_ShownCars mt-40 mb-40" dir={isLang === "en" ? 'ltr' : 'rtl'} >

          <Row style={{ width: '95%' }} className='m-auto'>

            {
              !LoadingCars ?
                <>
                  {
                    carData[first + 1]?.map((item, index) => (
                      <>
                        <Col key={index} xl={4} className='relative  flex justify-content-center align-items-center mt-6 ' lg={4} md={6} sm={12}>
                          <Card
                            title={`${isLang === "en" ? item.brand : item.brand_arabic}` + '  ' + `${isLang === "en" ? item.sub_brand : item.sub_brand_arabic}`}
                            header={<img src={item.image}
                              className='w-100  IMA_CAR' alt={`${isLang === "en" ? item.brand : item.brand_arabic}` + '  ' + `${isLang === "en" ? item.sub_brand : item.sub_brand_arabic}`} loading='lazy' />}
                          >
                            <div className="slider_card" dir={isLang === "en" ? 'ltr' : 'rtl'}>
                              <ul>
                                <li>
                                  <Icons.SliderCarSedan />
                                  <strong className='bold'>{item.year} {' '} {isLang === "en" ? item.type : item.type_arabic}  </strong>
                                </li>
                                <li>
                                  <Icons.SliderCarMoney />
                                  {item.offer !== 0 && <span className='app__discount'>{item.price} {isLang === "en" ? 'SR' : 'ريال'} </span>}
                                  {
                                    item.offer === 0 ?
                                      <span>{item.price} {isLang === "en" ? 'SR' : 'ريال'}</span>
                                      :
                                      <span>{item.offer_price} {isLang === "en" ? 'SR' : 'ريال'}</span>
                                  }

                                  <span className='oneDay mr-4' >  {isLang === "en" ? 'Per day/tax included' : 'لليوم / شامل الضريبة'}</span>

                                </li>
                                <li>
                                  <Icons.SliderCarPeople />
                                  <span>{item.pasengers} {isLang === "en" ? 'Pasengers' : 'راكب'}   </span>
                                </li>
                                {item.aircondition !== 0 && <li>
                                  <Icons.SliderCarSoil />
                                  <span>    {isLang === "en" ? 'Aircondition' : 'مكيفة'} </span>
                                </li>}

                                {item.aircondition === 0 && <li>
                                  <Icons.SliderCarSoil />
                                  <span>    {isLang === "en" ? 'Non-air conditioned' : 'غير مكيفة'} </span>
                                </li>}


                              </ul>
                              <div className="order   flex justify-content-end " >
                                <Link to={`/${isLang}/cars/${item.id}?car-name=${item.brand_arabic}_${item.sub_brand_arabic}`}>
                                  <Button label={isLang === "en" ? 'Book now ' : '  احجزها الآن '} size='small' severity="warning" />
                                </Link>

                              </div>
                            </div>
                          </Card>
                          {
                            item.offer !== 0 && <div className="price absolute" dir={isLang === "en" ? 'ltr' : 'rtl'}>
                              <span>{isLang === "en" ? 'off' : 'خصم'}</span>
                              {item.percentage}%
                            </div>
                          }
                        </Col>
                      </>
                    ))
                  }
                </>
                :
                <>

                  <Col xl={4} className='relative flex justify-content-center align-items-center mt-6 ' lg={4} md={6} sm={12}>
                    <Skeleton size="20rem"></Skeleton>
                  </Col>
                  <Col xl={4} className='relative flex justify-content-center align-items-center mt-6 ' lg={4} md={6} sm={12}>
                    <Skeleton size="20rem"></Skeleton>
                  </Col>
                  <Col xl={4} className='relative flex justify-content-center align-items-center mt-6 ' lg={4} md={6} sm={12}>
                    <Skeleton size="20rem"></Skeleton>
                  </Col>
                </>
            }
            {
              page ?
                <div className="p-4">
                  <div className="card mt-4 " dir='ltr'>
                    <Paginator first={first} rows={rows} totalRecords={page * rows} onPageChange={onPageChange} template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }} />
                  </div>
                </div> : ''
            }
          </Row>
        </div>
      </div>
    </div>
  )
}

export default ShownCars
