import { motion } from 'framer-motion';
import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Icons from '../../constants/Icons';
import img from '../../constants/Img';
import { SharedData } from '../../context/CallApi';
import { LocalizationContext } from '../../context/LangChange';
import './footer.scss';
import { CarFilterStates } from '../../context/FiltersCars';
const Footer = () => {
  let { isLang } = useContext(LocalizationContext);
  const location = useLocation();
  let { contactsData } = useContext(SharedData);
  let { activeBtn, setActiveBtn } = useContext(CarFilterStates);



  const urlSearchParams = new URLSearchParams(window.location.search);

  useLayoutEffect(() => {
    const findCarTypeParam = urlSearchParams.get('find-car-type');
    if (findCarTypeParam === 'long-term-rent') {
      setActiveBtn('btn2');
      window.scrollBy(0, -window.pageYOffset);

    } else if (findCarTypeParam === 'short-term-rent') {

      setActiveBtn('btn1');
      window.scrollBy(0, -window.pageYOffset);

    } else if (findCarTypeParam === 'limousine') {

      setActiveBtn('btn3');
      window.scrollBy(0, -window.pageYOffset);

    }
  }, []);

  return (
    <div>
      <div className="app__footer pt-40  overflow-hidden" dir={isLang === "en" ? 'ltr' : 'rtl'}>
        <Container>
          <Row  >
            <Col xl={3} lg={3} md={6} sm={12} className='mt-4 order-md-4 order-lg-0 order-sm-4 footer_lg'>
              {/* mr-4  ${isLang !== "en" ? 'mr-3' : 'ml-5  '} */}
              <div className={`social  `}>
                <motion.a
                  href={contactsData?.twitter}
                  target='_blank' rel="noreferrer"
                  whileHover={{ scale: 1.2 }}
                  transition={{ duration: 0.3 }}
                >
                  <Icons.Twitter color={'#CE931A'} />
                </motion.a>

                <motion.a
                  href={contactsData?.facebook}
                  target='_blank' rel="noreferrer"
                  whileHover={{ scale: 1.2 }}
                  transition={{ duration: 0.3 }}
                >
                  <Icons.Facebook color={'#CE931A'} className='facebook' />
                </motion.a>

                <motion.a
                  href={contactsData?.instagram}
                  target='_blank' rel="noreferrer"
                  whileHover={{ scale: 1.2 }}
                  transition={{ duration: 0.3 }}
                >
                  <Icons.Instagram color={'#CE931A'} />
                </motion.a>

                {/* <motion.a
                  href={contactsData?.linkedin}
                  target='_blank' rel="noreferrer"
                  whileHover={{ scale: 1.2 }}
                  transition={{ duration: 0.3 }}
                >
                  <Icons.Linkedin color={'#CE931A'} />
                </motion.a> */}
              </div>
              <div className="flex flex-column     gap-3 mt-35">
                <motion.a
                  href={contactsData?.ios}
                  target='_blank' rel="noreferrer"
                  whileHover={{ scale: 1.2 }}
                  transition={{ duration: 0.3 }}
                >
                  <img src={img.AppStoreLight} width={150} alt="AppStoreLight" loading='lazy' />
                </motion.a>
                <motion.a
                  href={contactsData?.android}
                  target='_blank' rel="noreferrer"
                  whileHover={{ scale: 1.2 }}
                  transition={{ duration: 0.3 }}
                >
                  <img src={img.GoogleplayLight} alt="GoogleplayLight" width={150} />
                </motion.a>
              </div>
            </Col>

            <Col xl={6} lg={6} md={12} sm={12} className='flex flex-row gap-sm-8'>
              <Col xl={6} lg={6} md={6} sm={6} className='mt-4  order-md-2 order-lg-2 order-sm-2' >
                <div className='footer_Links' >
                  <h2> {isLang === "en" ? 'Links' : 'روابـــط'} </h2>
                  <div className="footer_ui">
                    <ul >
                      <li> <Nav.Link as={Link} to={`/${isLang}`} className={`nav-link ${location.pathname === `/${isLang}` ? 'active active_main_color' : ''}`}>  {isLang === "en" ? 'Home' : 'الرئيسية'} </Nav.Link> </li>
                      <li> <Link to={`/${isLang}/cars`} className={`nav-link ${location.pathname === `/${isLang}/cars` ? 'active active_main_color' : ''}`}> {isLang === "en" ? 'Fleet ' : 'أسطـول الغزال '} </Link> </li>
                      <li> <Link to={`/${isLang}/offers`} className={`nav-link ${location.pathname === `/${isLang}/offers` ? 'active active_main_color' : ''}`}>{isLang === "en" ? 'Offers ' : 'العروض '}</Link>  </li>
                      <li> <Link to={`/${isLang}/branches`} className={`nav-link ${location.pathname === `/${isLang}/branches` ? 'active active_main_color' : ''}`}>{isLang === "en" ? 'Branches' : ' الفروع'}</Link>  </li>
                      <li> <Link to={`/${isLang}/privacy-policy`} className={`nav-link ${location.pathname == `/${isLang}/privacy-policy` ? 'active active_main_color' : ''}`}>{isLang === "en" ? 'Privacy Policy' : ' سياسة الخصوصية'}</Link>  </li>
                      <li> <Link to={`/${isLang}/terms-of-use`} className={`nav-link ${location.pathname === `/${isLang}/terms-of-use` ? 'active active_main_color' : ''}`}>{isLang === "en" ? 'Terms Of Use' : 'شروط الاستخدام'}</Link>  </li>
                      <li> <Link to={`/${isLang}/terms-cancellation`} className={`nav-link ${location.pathname === `/${isLang}/terms-cancellation` ? 'active active_main_color' : ''}`}>{isLang === "en" ? 'Terms Cancellation' : 'شروط الالغاء'}</Link>  </li>

                    </ul>
                  </div>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} className='mt-4 order-md-3 order-lg-3 order-sm-3'  >
                <div className='footer_Links' >
                  <h2> {isLang === "en" ? 'Additional links' : 'روابـــط إضافية'} </h2>
                  <div className="footer_ui">
                    <ul >

                      <li> <Link to={`/${isLang}/career`} className={`nav-link ${location.pathname === `/${isLang}/career` ? 'active active_main_color' : ''}`}>   {isLang === "en" ? 'Jobs   ' : 'الوظائف المتاحة '} </Link> </li>
                      <li> <Link to={`/${isLang}/about`} className={`nav-link ${location.pathname === `/${isLang}/about` ? 'active active_main_color' : ''}`}> {isLang === "en" ? 'About Us ' : 'مــن نــحــن'} </Link> </li>
                      <li> <Link to={`/${isLang}/contactus`} className={`nav-link ${location.pathname === `/${isLang}/contactus` ? 'active active_main_color' : ''}`}> {isLang === "en" ? ' Contact Us' : 'تواصل معنا '}   </Link> </li>
                      <li> <Link to={`/${isLang}/cars/?find-car-type=short-term-rent`}
                        onClick={(e) => {
                          setActiveBtn('btn1')
                          window.scrollBy(0, -window.pageYOffset);
                        }}
                        className={`nav-link ${urlSearchParams.get('find-car-type') === 'short-term-rent' ? 'active active_main_color' : ''}`}  > {isLang === "en" ? 'Individuals' : 'افراد '}   </Link> </li>
                      <li> <Link to={`/${isLang}/?find-car-type=long-term-rent `}
                        onClick={(e) => {
                          setActiveBtn('btn2')
                          window.scrollBy(0, -window.pageYOffset);
                        }}
                        className={`nav-link ${urlSearchParams.get('find-car-type') === 'long-term-rent' ? 'active active_main_color' : ''}`}> {isLang === "en" ? 'Companies' : 'الشركــات'}   </Link> </li>
                      {/* <li> <Link to={'/cars/limousine?find-car-type=limousine'}
                        onClick={(e) => {
                          setActiveBtn('btn3')
                          window.scrollBy(0, -window.pageYOffset);
                        }}
                        className={`nav-link ${urlSearchParams.get('find-car-type') === 'limousine' ? 'active active_main_color' : ''}`}> {isLang === "en" ? 'Limousine' : 'ليموزين'}   </Link> </li> */}
                      {/* <li> <Link to={'/cars'}>حسابى   </Link> </li> */}
                    </ul>
                  </div>
                </div>
              </Col>
            </Col>

            <Col xl={3} lg={3} md={6} sm={12} className='mt-4 order-md-1 order-lg-1 order-sm-1 footer_lg' >
              <div className='footer_Links' >
                <h2> {isLang === "en" ? 'Support  ' : '  الدعــم'} </h2>
                <div className="footer_ui">
                  <ul >
                    <li>{isLang === "en" ? 'Saudi Arabia  ' : 'المملكة العربية السعودية '}</li>
                    <li >  <a href={`mailto:${contactsData?.email}`} target='_blank' className='text-dark'>{contactsData?.email} </a> </li>
                    <li dir='ltr'>  <a href={`tel:${contactsData?.phone}`} target='_blank' >{contactsData?.phone} </a>    </li>
                    <li className='payment_icons flex flex-row gap-3'>
                      <Icons.Mastercard />
                      <Icons.Visa />
                      <Icons.Mada />
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
          <hr className='hr__footer' />
          <div className="footer__buttom pb-3  flex justify-content-center align-items-center">
            <span className='text-center'>Copyright © 2024, Alghazal.com, All Rights Reserved</span>
          </div>
        </Container>
      </div>
    </div >
  )
}

export default Footer
