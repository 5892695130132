import React, { useContext, useRef, useState } from 'react'
import img from '../../../constants/Img'
import { useFormik } from 'formik';
import axios from 'axios';
import { LocalizationContext } from '../../../context/LangChange';
import { Container } from 'react-bootstrap';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Messages } from 'primereact/messages';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import Icons from '../../../constants/Icons';
import { SharedData } from '../../../context/CallApi';
import toast from 'react-hot-toast'

const ActivationAccount = () => {
    const [loadmobile, setLoadmobile] = useState(false);
    const msgs = useRef(null);
    let { isLang } = useContext(LocalizationContext);
    const [otp, setOtp] = React.useState('')
    let navigate = useNavigate();
    let { fetchCities, contactsData } = useContext(SharedData);

    const formikCheckOtp = useFormik({
        initialValues: {
            code: '',
        },
        // validationSchema: validateRestpasswordForm,
        onSubmit: async (values) => {
            setLoadmobile(true)
            const url = `${process.env.REACT_APP_API_URL}/otpcheck?mobile=${localStorage.getItem('loginValue')}&otp=${values.code}`;
            let { data } = await axios.post(url)

            if (data?.status === true) {
                // msgs.current.clear();
                // msgs.current.show({ sticky: true, severity: 'success', summary: ` `, detail: data?.msg === "OTP_EMAIL_SENT" && isLang === "en" ? 'OTP has been sent to your email' : 'تم إرسال otp  لبريدك إلكتروني ' });
                toast.success(isLang === "en" ? 'Your account has been activated ' : 'تم تفعيل حسابك') 
                setTimeout(() => {
                    // navigate('/thanks-page/sign-up')
                    navigate('/auth/sign-in')
                    setLoadmobile(false)
                }, 1500);

            } else {
                toast.error(isLang === "en" ? 'This account is not registered' : 'هذا الحساب غير مسجل')
                // msgs.current.clear();
                // msgs.current.show({ sticky: true, severity: 'error', summary: ` `, detail: data?.msg === "CLIENT_NOT_FOUND" && isLang === "en" ? 'This account is not registered' : 'هذا الحساب غير مسجل' });

                setTimeout(() => {
                    setLoadmobile(false)
                }, 500);
            }

        }
    });
    const handleChange = (newValue) => {
        setOtp(newValue)
        formikCheckOtp.setFieldValue('code', newValue)
    }

    return (
        <div className='activation_style'>
            <div className="app_form flex-column">
                <header className='flex justify-content-center'>
                    <img src={img.Logo3} alt="logo alghazal" width={120} />
                </header>
                <section className='flex flex-column justify-content-center align-items-center'>
                    <span className='text-center'>{isLang == "en" ? 'Thank you for registering' : 'شكرا لك علي التسجيل'}</span>
                    {
                        localStorage.getItem('loginBy') == "phone" ?
                            <span className='mt-2 text-center'>{isLang == "en" ? 'The OTP has been sent via WhatsApp' : 'تم إرسال الرقم المتغير على تطبيق الواتس اب'}</span> :
                            <span className='mt-2 text-center' >{isLang == "en" ? 'The OTP has been sent to your email address' : '  تم إرسال الرقم المتغير على البريد الإلكتروني الخاص بك'}</span>

                    }
                </section>
                <form onSubmit={formikCheckOtp.handleSubmit} className='w-100'>
                    <Container className='flex justify-content-center align-items-center flex-column'>
                        {/* <h1> {isLang === "en" ? 'Forgot password' : 'نسيت كلمة المرور'}</h1> */}
                        <div className="Inputs w-100 flex gap-3 flex-column  "   >
                            <div className=" flex flex-column gap-2 BG-INFO mt-4   ">{/* 
                                            <label htmlFor="username">{isLang === "en" ? 'OTP' : '   رقم  OTP'}  </label> */}
                                <div className="otp_input" dir='ltr'>
                                    <MuiOtpInput name="code" value={otp} onChange={handleChange} length={4} />
                                </div>
                                {formikCheckOtp.errors.mobile && formikCheckOtp.touched.mobile ?
                                    <Messages severity="error" text={formikCheckOtp.errors.mobile} /> : null
                                }
                            </div>
                            {/*             <div className={`${isLang === "en" ? '' : 'Messages_Ar'} w-100 flex justify-content-center`} >
                                <Messages ref={msgs} className='w-100' />
                            </div> */}
                            <div className="contact-container">
                                <p className="contact-message font-bold text-sm "> {isLang === "en" ? ' If you have any questions, please contact me' : 'اذا كان لديك اي اسئلة من فضلك تواصل علي'}</p>
                                <div className="contact-info flex flex-column justify-content-center align-items-center  ">
                                    <div className={`flex flex-column ${isLang == "en" ? 'align-items-start' : 'align-items-end'} `}>
                                        <div className="contact-email  " dir={isLang === "en" ? 'ltr' : 'rtl'} >
                                            <Icons.Email3 />
                                            <span className="contact-label">{isLang === "en" ? 'E-mail' : ' البريد الالكترونى'} </span>
                                            <a href={`mailto:${contactsData?.email} `} target="_blank" className="contact-link"> {contactsData?.email}</a>
                                        </div>
                                        <div className="contact-phone" dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                            <Icons.Call2 />
                                            <span className="contact-label">{isLang === "en" ? ' Mobile Number' : ' رقم الجــوال  '} </span>
                                            <a href={`tel:${contactsData?.phone} `} target="_blank" className="contact-link" dir='ltr'>{contactsData?.phone}</a>
                                        </div>
                                        <div className="contact-whatsapp" dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                            <Icons.Chat />
                                            <span className="contact-label">   {isLang === "en" ? 'Whatsapp' : 'واتس اب '} </span>
                                            <a href={`https://wa.me/${contactsData?.whatsapp}`} target="_blank" className="contact-link" dir='ltr'> {contactsData?.whatsapp} </a>
                                        </div>

                                    </div>
                                </div>
                            </div>



                            <div className="flex justify-content-center align-items-center">
                                <div className="flex flex-column   ">
                                    <div className='w-100 btn_otp_submit'>
                                        <Button loading={loadmobile} disabled={loadmobile} label={isLang === "en" ? 'Login Now' : 'قم بتسجيل الدخول الان'} className='w-100' severity='secondary' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </form>
            </div>
            <Link as={Link} to={'/auth/sign-in'}>
                <div className="btn_back cursor-pointer shadow-lg" >
                    <i className={`pi ${isLang === "en" ? "pi-arrow-right " : "pi-arrow-left"} `}></i>
                </div>
            </Link>
        </div>
    )
}

export default ActivationAccount
