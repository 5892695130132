import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { Message } from 'primereact/message';
import { Messages } from 'primereact/messages';
import { Password } from 'primereact/password';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap'; // Remove Modal import
import { Link, useNavigate } from 'react-router-dom';
import { SharedData } from '../../../context/CallApi';
import { LocalizationContext } from '../../../context/LangChange';
import { handelRegister } from '../Services/AuthService';
import useValidation from '../Services/useValidation';
import img from './../../../constants/Img';
import Nationalities from './Nationalities';
import './style.scss';
import Component from '../../../constants/Component';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // لاستيراد الأنماط
import ar from './ar.json';

const Register = () => {
  let { isLang } = useContext(LocalizationContext);
  const msgs = useRef(null);
  const nationality = Nationalities();
  const [enteredPassword, setEnteredPassword] = useState('');
  let navigate = useNavigate();
  const [loadmobile, setLoadmobile] = useState(false);

  let { validateRegisterForm } = useValidation();
  let { fetchAllCities } = useContext(SharedData);
  const [checked, setChecked] = useState(false);
  const [handelOpenPassword, setHandelOpenPassword] = useState('password');
  const [handelOpenPassword1, setHandelOpenPassword1] = useState('password');

  const gender = [
    { name: isLang === "en" ? 'male' : 'ذكر', value: 0 },
    { name: isLang === "en" ? 'female' : 'أنثي', value: 1 },
  ];



  const showError = (data) => {
    msgs.current.show({ sticky: true, severity: 'error', detail: data });
  };

  const showSuccess = (data) => {
    msgs.current.show({ sticky: true, severity: 'success', summary: `${isLang === "en" ? 'success' : 'متاح : '} `, detail: data });
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      mobile: '',
      email: '',
      nationality: '',
      // nationalId: '',
      gender: '',
      cityId: '',
      address: '',
      password: '',
      acceptTerms: false,
    },
    validationSchema: validateRegisterForm,
    // isValid: false,  
    onSubmit: (values) => handelRegister(values, setLoadmobile, isLang).then((data) => {

      const { detail, closeModal } = data;
      msgs.current.clear();

      // toast.current.show({ severity, summary, detail, life: 3000 });
      if (closeModal) {
        if (values?.email && values?.mobile) {
          localStorage.setItem('loginBy', 'email')
          localStorage.setItem('loginValue', values?.email)
        } else if (values?.mobile) {
          localStorage.setItem('loginBy', 'phone')
          localStorage.setItem('loginValue', values?.mobile) 
        } else if (values?.email) {
          localStorage.setItem('loginBy', 'email')
          localStorage.setItem('loginValue', values?.email)

        }
        setTimeout(() => {
          navigate('/auth/activation')
        }, 1000);
        showSuccess(detail)
      } else {

        showError(detail)
      }
    })
  });


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const handleCloseTermsPopup = () => setShowTermsPopup(false);
  useEffect(() => {
    window.scrollBy(0, -window.pageYOffset);
  }, [])

  return (
    <>
      <div className='app__login h-100 app__register'>
        <Row dir={isLang === "en" ? 'ltr' : 'rtl'} className='h-100  '>
          <Col xl={8} lg={8} md={6} sm={12} className="bg_login_md_view app__Redister-contect "  >
            <form onSubmit={formik.handleSubmit} className='w-100'>

              <Container className='flex   flex-column pt-5 pb-5'>
                <h1> {isLang === "en" ? 'Registration' : 'تسجيل جديد '}</h1>

                <div className="Inputs w-12 flex gap-3 flex-column  "   >
                  <div className="grid">
                    <div className="lg:col-12 md:col-12 sm:col-12 xs:col-12 input__Col  mt-3">
                      <div className="flex  gap-2 align-items-center mb-2">
                        <Checkbox inputId="ingredient1" name="pizza" value="Cheese"
                          onChange={(e) => {
                            setChecked(e.checked)
                            navigate('/auth/check-account')
                          }}
                          checked={checked} />
                        <label htmlFor="ingredient1" className="ml-2 text_box">{isLang === "en" ? 'Are you a former alghazal customer??' : 'هل انت عميل سابق في الغزال؟'}   </label>
                      </div>
                    </div>

                    <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col  mt-3">
                      <label htmlFor="firstName" className="font-bold block mb-2">   {isLang === "en" ? ' First Name' : '  الاسم الأول  '} </label>
                      <InputText
                        id="firstName"
                        name="firstName"
                        type="text"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        className="w-full p-inputtext-sm"
                        dir='ltr'
                      />
                      {formik.errors.firstName && formik.touched.firstName ?
                        <Message severity="error" text={formik.errors.firstName} /> : null
                      }
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col mt-3" >
                      <label htmlFor="lastName" className="font-bold block mb-2">{isLang === "en" ? ' Last Name' : ' اسم العائلة   '}  </label>
                      <InputText
                        id="lastName"
                        name="lastName"
                        type="text"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        className="w-full p-inputtext-sm"
                        dir='ltr'
                      />
                      {formik.errors.lastName && formik.touched.lastName ?
                        <Message severity="error" text={formik.errors.lastName} /> : null
                      }
                    </div>
                    <div dir='ltr' className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col  mt-3">
                      <label htmlFor="mobile" dir={isLang == "en" ? 'ltr' : 'rtl'} className="font-bold block mb-2">{isLang === "en" ? ' Mobile Number' : '  رقم الجوال '}   </label>
                      {/*               <InputText
                        id="mobile"
                        name="mobile"
                        type="text"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        className="w-full p-inputtext-sm"
                        dir='ltr'
                      />   */}
                      <PhoneInput
                        country='sa'
                        enableSearch={true}
                        id="mobile"
                        name="mobile"
                        preferredCountries={['sa']}
                        // onChange={(value) => formik.setFieldValue('mobile', value)}
                        onChange={(value, countryData) => {
                          // استخراج مفتاح الدولة من بيانات الدولة
                          let dialCode = countryData.dialCode; 
                          let newValue = value.replace(dialCode, ''); 
                          if (newValue.startsWith('0')) {
                            newValue = newValue.substring(1);
                          } 
                          newValue = dialCode + newValue;  
                           formik.setFieldValue('mobile', newValue);
                        }}
                        onBlur={formik.handleBlur}
                        dir="ltr"
                        containerClass="w-full"
                        className="w-full p-inputtext-sm"
                        value={formik.values.mobile}

                        inputClass={'w-100'}
                        // localization={ar}   
                        inputExtraProps={{
                          autoFocus: true,
                        }}
                      />
                      {formik.errors.mobile && formik.touched.mobile ?
                        <Message severity="error" dir={isLang == "en" ? 'ltr' : 'rtl'} text={formik.errors.mobile} /> : null
                      }
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col  mt-3">
                      <label htmlFor="email" className="font-bold block mb-2"> {isLang === "en" ? ' Email' : '  البريد الالكترونى   '}    </label>
                      <InputText
                        id="email"
                        name="email"
                        type="email"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        className="w-full p-inputtext-sm"
                        dir='ltr'
                      />
                      {formik.errors.email && formik.touched.email ?
                        <Message severity="error" text={formik.errors.email} /> : null
                      }
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col mt-3" >
                      <label htmlFor="title_ar" className="font-bold block mb-2"> {isLang === "en" ? ' Nationality' : '   الجنسية'}    </label>
                      <Dropdown
                        filter
                        id="nationality"
                        name="nationality"
                        value={formik.values.nationality}
                        options={nationality}
                        optionLabel="name"
                        onChange={(e) => formik.setFieldValue("nationality", e.value)}
                        onBlur={formik.handleBlur}
                        optionValue="name"
                        placeholder={isLang === "en" ? 'Select your nationality' : ' اختر جنسيتك   '}
                        className="w-full p-inputtext-sm"
                      />
                      {formik.errors.nationality && formik.touched.nationality ?
                        <Message severity="error" text={formik.errors.nationality} /> : null
                      }
                    </div>

                    {/* <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col mt-3" >
                      <label htmlFor="nationalId" className="font-bold block mb-2">{isLang === "en" ? 'ID Number' : ' رقم الهوية  '} </label>
                      <InputText
                        id="nationalId"
                        name="nationalId"
                        type="text"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        className="w-full p-inputtext-sm"
                        dir='ltr'
                      />
                      {formik.errors.nationalId && formik.touched.nationalId ?
                        <Message severity="error" text={formik.errors.nationalId} /> : null
                      }
                    </div> */}
                    <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col mt-3 ">
                      <label htmlFor="gender" className="font-bold block mb-2">
                        {isLang === "en" ? 'Gender  ' : '  النوع '}
                      </label>
                      <Dropdown
                        id="gender"
                        name="gender"
                        value={formik.values.gender}
                        options={gender}
                        optionLabel="name"
                        onChange={(e) => formik.setFieldValue("gender", e.value)}
                        onBlur={formik.handleBlur}
                        placeholder={isLang === "en" ? 'Select a gender' : 'اختر النوع'}
                        className="w-full p-inputtext-sm"
                      />
                      {formik.errors.gender && formik.touched.gender ? (
                        <Message severity="error" text={formik.errors.gender} />
                      ) : null}

                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col mt-3" >
                      <label htmlFor="cityId" className="font-bold block mb-2">{isLang === "en" ? 'City ' : '  المدينة '} </label>
                      <Dropdown
                        filter
                        id="cityId"
                        name="cityId"
                        value={formik.values.cityId}
                        options={fetchAllCities.map(city => ({
                          name: isLang === 'en' ? city.name : city.arabic_name,
                          value: city.id,
                        }))}
                        optionLabel="name"
                        onChange={(e) => formik.setFieldValue("cityId", e.value)}
                        onBlur={formik.handleBlur}
                        placeholder={isLang === "en" ? 'Select a city' : 'اختر المدينة'}
                        className="w-full p-inputtext-sm"
                      />
                      {formik.errors.cityId && formik.touched.cityId ?
                        <Message severity="error" text={formik.errors.cityId} /> : null
                      }
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col  mt-3">
                      <label htmlFor="address" className="font-bold block mb-2">{isLang === "en" ? ' Address' : ' العنوان  '}    </label>
                      <InputText
                        id="address"
                        name="address"
                        type="text"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        className="w-full p-inputtext-sm"
                        dir='ltr'
                      />
                      {formik.errors.address && formik.touched.address ?
                        <Message severity="error" text={formik.errors.address} /> : null
                      }
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col mt-3" >
                      <label htmlFor="password" className="font-bold block mb-2">  {isLang === "en" ? 'P  assword ' : ' كلمة المرور         '}   </label>
                      {
                        handelOpenPassword === "password" ?
                          <div className="password_open">
                            <Password
                              id="password"
                              name="password"
                              type={handelOpenPassword}
                              onBlur={formik.handleBlur}
                              value={enteredPassword}
                              onChange={(e) => {
                                setEnteredPassword(e.target.value);
                                formik.handleChange(e);
                              }}
                              className="p-inputtext-sm"
                              dir='ltr'
                              footer={formik.errors.password && formik.touched.password ?
                                <div dir={isLang !== "en" ? 'ltr' : 'rtl'} >
                                  <Message severity="error" text={formik.errors.password} />
                                </div>
                                : <Message severity="error" text='' />
                              }
                            />

                            {
                              handelOpenPassword === "password" ?
                                <i className="pi pi-eye-slash app__login-btn-icons" onClick={() => setHandelOpenPassword('text')}  ></i> :
                                <i className="pi pi-eye app__login-btn-icons" onClick={() => setHandelOpenPassword('password')}  ></i>
                            }
                          </div> :
                          <div className="password_open">
                            <InputText
                              id="password"
                              name="password"
                              type={handelOpenPassword}
                              onBlur={formik.handleBlur}
                              value={enteredPassword}
                              onChange={(e) => {
                                setEnteredPassword(e.target.value);
                                formik.handleChange(e);
                              }}
                              className="p-inputtext-sm w-full"
                              dir='ltr'
                            />

                            {
                              handelOpenPassword === "password" ?
                                <i className="pi pi-eye-slash app__login-btn-icons" onClick={() => setHandelOpenPassword('text')}  ></i> :
                                <i className="pi pi-eye app__login-btn-icons" onClick={() => setHandelOpenPassword('password')}  ></i>
                            }
                          </div>

                      }
                      {formik.errors.password && formik.touched.password ?
                        <Message severity="error" text={formik.errors.password} /> : <Message severity="error" text='' />
                      }
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12   input__Col  mt-3">
                      <label htmlFor="confirmPassword" className="font-bold block mb-2"> {isLang === "en" ? 'Confirm Password   ' : 'تأكيد كلمة المرور    '}  </label>
                      <div className="password_open">
                        <InputText
                          id="confirmPassword"
                          name="confirmPassword"
                          type={handelOpenPassword1}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          className="w-full p-inputtext-sm"
                          dir='ltr'
                        />
                        {
                          handelOpenPassword1 === "password" ?
                            <i className="pi pi-eye-slash app__login-btn-icons" onClick={() => setHandelOpenPassword1('text')}  ></i> :
                            <i className="pi pi-eye app__login-btn-icons" onClick={() => setHandelOpenPassword1('password')}  ></i>

                        }
                      </div>
                      {formik.errors.confirmPassword && formik.touched.confirmPassword ?
                        <Message severity="error" text={formik.errors.confirmPassword} /> : null
                      }
                    </div>
                  </div>

                  <div className="forget_password">
                    <div className="flex flex-column">
                      {/* <div className="flex  gap-2 align-items-center mb-2">
                        <Checkbox inputId="ingredient1" name="pizza" value="Cheese" onChange={e => setChecked(e.checked)} checked={checked} />
                        <label htmlFor="ingredient1" className="ml-2 text_box">{isLang === "en" ? 'Are you a former alghazal customer??' : 'هل انت عميل سابق في الغزال؟'}   </label>
                      </div> */}
                      <div className="flex  gap-2 align-items-center mb-2">
                        <Checkbox
                          inputId="acceptTerms"
                          name="acceptTerms"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.acceptTerms} />
                        <Link >
                          <label htmlFor="" className="ml-2 text_box">
                            {isLang === "en" ?
                              <>I accept <a onClick={setShowTermsPopup} className='text-decoration-underline cursor-pointer text-dark'>all terms</a> and  <a onClick={setShow} className='text-decoration-underline cursor-pointer text-dark'>privacy policy </a> </>
                              : <div className='flex flex-row-reverse text-sm-re gap-1'>

                                <a onClick={setShow} className='text-decoration-underline cursor-pointer text-dark'>  سياسة الخصوصية  </a>
                                و  <a onClick={setShowTermsPopup} className='text-decoration-underline cursor-pointer text-dark'> جميع الشروط </a>  اوافق علي
                              </div>}
                          </label>
                        </Link>
                      </div>
                      {formik.errors.acceptTerms && formik.touched.acceptTerms ?
                        <Message severity="error" text={formik.errors.acceptTerms} /> : null
                      }
                    </div>

                    {/* <p>
                        {isLang === "en" ? ' Forgot password?' : '  هل نسيت كلمة السر؟ '}
                      </p> */}
                  </div>
                  <div className={`${isLang === "en" ? '' : 'Messages_Ar'} w-100 flex justify-content-center`} >
                    <Messages ref={msgs} className='w-100' />
                  </div>
                  <div className="flex flex-column       ">
                    <Button loading={loadmobile} label={isLang === "en" ? 'Create your account' : 'انشاء الحساب'} type='submit' severity='warning' className='w-10 m-auto' />
                  </div>


                </div>

                <div className="app__now_account flex justify-content-center align-items-center">
                  <span >{isLang === "en" ? 'Do you have an account? ' : '  لديك حساب ؟   '}  <Link to={'/auth/sign-in'} className='app__now_account_sign_in'>{isLang === "en" ? ' Sign In' : ' تسجيل الدخول  '} </Link></span>
                </div>

              </Container>

            </form>
          </Col>
          <Col xl={4} lg={4} md={6} sm={12} className='relative bg_login_md' >
            <div className="app__login-image h-100">
              <img src={img.RedisterBg} className='w-100 h-100' alt="RedisterBg" loading='lazy' />
              <Link to={'/auth/sign-in'}>
                <div className="btn_back cursor-pointer shadow-lg" >
                  <i className={`pi ${isLang === "en" ? "pi-arrow-right " : "pi-arrow-left"} `}></i>
                </div>
              </Link>
            </div>
          </Col>
          <Component.PrivacyPolicyPopup
            handleClose={handleClose}
            show={show}
          />


          <Component.TermsPopup
            handleClose={handleCloseTermsPopup}
            show={showTermsPopup}
          />
        </Row>
      </div>
    </>
  )
}

export default Register;
