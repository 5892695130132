import { Button } from 'primereact/button';
import { Galleria } from 'primereact/galleria';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LocalizationContext } from '../../../../context/LangChange';


import './Slider.scss';
const Slider = ({ dataHome }) => {
    let { isLang } = useContext(LocalizationContext); 
  
    const itemTemplate = (item) => {
        return (
            <div className='image__container'>
                <img src={item.image} alt= {isLang === "en" ? item.title : item.arabic_title} className='image__slider'loading='lazy' />

                <div className={`${isLang === "en" ? 'content__silder  ' : 'content__silder content__silder-rtl'}  `}>
                    <div className={`content ${isLang === "en" ? 'contentEn' : 'contentAr'} `}>
                        <span >  {isLang === "en" ? item.title : item.arabic_title} </span>
                        <p className={`${isLang === "en" ? 'text-left  ' : 'text-right'}  `}>   {isLang === "en" ? item.desc : item.arabic_desc} </p>
                        <Link className='link__home_btn_sidebar' to={'/cars'}>
                            <Button severity='warning' label={isLang === "en" ? 'Browse now' : 'تصفح الان'} />
                        </Link>
                    </div>
                </div>
            </div>
        );
    };


    return (
        <div style={{ width: '100%' }}>
            <Galleria value={dataHome} showThumbnails={false}
                className="image-slider-indicators"
                showIndicatorsOnItem={true}
                circular autoPlay transitionInterval={2000}
                showIndicators item={itemTemplate} />
        </div>
    );
}

export default Slider;
