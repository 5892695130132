import clipboardCopy from 'clipboard-copy'
import React, { useContext, useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { SharedData } from '../../../context/CallApi'
import { LocalizationContext } from '../../../context/LangChange'
import './Points.scss'
import Icons from './../../../constants/Icons';
import img from './../../../constants/Img';
import axios from 'axios'
import { Helmet } from 'react-helmet-async'

const Points = () => {
  let { isLang, isOpen } = useContext(LocalizationContext);
  let { fetchPersonalData } = useContext(SharedData)

  const [modalShow, setModalShow] = React.useState(false);
  const textToCopy = "هذا هو النص الذي تريد نسخه";
  useEffect(() => {
    window.scrollBy(0, -window.pageYOffset);
  }, [])

  const handleCopyClick = async (points) => {
    const getcClientId = localStorage.getItem('client_id');

    try {
      const url = `${process.env.REACT_APP_API_URL}/genpromo?clientId=${getcClientId}&points=${points}`;
      let { data } = await axios.post(url)
      if (data?.status === true) {
        await clipboardCopy(data?.promo);
        toast.success(isLang === "en" ? 'Discount code copied !' : '! تم  نسخ كود الخصم ')
      }


    } catch (error) {
      console.error('حدث خطأ أثناء النسخ: ', error);
    }
  };
  const [data, setData] = useState(null)
  const fetchPoints = async () => {
    const getcClientId = localStorage.getItem('client_id');

    const url = `${process.env.REACT_APP_API_URL}/points?clientId=${getcClientId}`;
    let { data } = await axios.get(url)
    setData(data);
  }

  useEffect(() => {
    fetchPoints()
  }, [])

  return (
    <>
      <Helmet > 
        <title>{isLang === "en" ? 'Points | Alghazal ' : '  النقاط | الغزال لتأجير السيارات'}</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="app__points mb-8" dir={isLang === "en" ? 'ltr' : 'rtl'}>
        <Container>
          <div className="points">
            <span>{isLang === "en" ? 'My Points' : 'النقاط الخاصة بك'}</span>
            <div className="num_points">
              {fetchPersonalData?.points}
            </div>
          </div>
          {/*     <div className="app__points_pay">
          <Button onClick={() => setModalShow(true)} severity='warning' icon={<Icons.Plus />} label='شحن رصيد' />
        </div> */}


          {/*        <Component.Wallet
          show={modalShow}
          onHide={() => setModalShow(false)}
        /> */}

          <div className="discount_codes mt-6">
            {/* <div className="title">
            <Icons.Rate />
            <span>{isLang === "en"?'Discount Codes':'أكواد خصم'}</span>
          </div> */}

            {/* <Row className='mt-4'>
            {
              data?.map((item, index) => (
                <Col xl={4} lg={4} md={6} sm={12} className='mt-6'>
                  <div className={`  ${isOpen ? 'app__points_xs' : ''}`} >
                    <div className='cursor-pointer' onClick={() => handleCopyClick(item?.points)}>
                      <div className="Card">
                        <img src={img.Discount_codes1} alt="images" />
                        <div className="title">
                          <h3> {isLang === "en" ? ' Redeem now ' : '   احصل علي الكود'}</h3>
                        </div>
                        <div className="content">
                          <div className="data">
                          {
                              isLang === "ar" ?
                              <h5>احصل على خصم {item?.money} ريال</h5>:
                              <h5>Get {item?.money} <sub>sar</sub> discount</h5>

                            }
                            {
                              isLang === "en" ?
                                <p>It is used at checkout as a discount code</p> :
                                <p>يستخدم عند الدفع ك كود خصم</p> 
                            }
                          </div>
                        </div>

                        <div className="discount_points">
                          <span>{isLang === "en" ? `${item?.points} points` : ` مقابل ${item?.points} نقطة   `}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </Col>
              ))
            }
          </Row> */}
          </div>
        </Container>

      </div>
    </>
  )
}

export default Points